// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$EzSchoolAngularApp-primary: mat.define-palette(mat.$indigo-palette);
$EzSchoolAngularApp-accent: mat.define-palette(
  mat.$pink-palette,
  A200,
  A100,
  A400
);

// The warn palette is optional (defaults to red).
$EzSchoolAngularApp-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$EzSchoolAngularApp-theme: mat.define-light-theme(
  (
    color: (
      primary: $EzSchoolAngularApp-primary,
      accent: $EzSchoolAngularApp-accent,
      warn: $EzSchoolAngularApp-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($EzSchoolAngularApp-theme);

/* You can add global styles to this file, and also import other style files */

/* Provide sufficient contrast against white background */
@import "assets/base";

a {
  color: #0366d6;
}

code {
  color: #e01a76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.timepicker-overlay {
  z-index: 10000000 !important;
}

.timepicker-backdrop-overlay {
  z-index: 10000000 !important;
}

/* // MODALS */
/*#region*/
.modal-content {
  border: 0 !important;
}

.modal-header h2 span {
  /* // margin-left: 7.75rem; */
  font-size: 1.6rem;
}

.modal-header h2 div.col-sm-8 {
  display: flex;
  align-items: center;
  margin: 0;
  /* // padding-left: 0; */
  font-size: 1.6rem;
}

@media only screen and (max-width: 767px) {
  .modal-header h2 span,
  .modal-header h2 div.col-sm-8 {
    font-size: 1.3rem;
  }

  .modal-header .col-sm-4 {
    max-width: 15%;
  }
  .modal-header .col-sm-8 {
    max-width: 75%;
  }
}

.modal-footer .btn-primary {
  background: #61b16c !important;
  border: 1px solid #61b16c !important;
  float: left;
  margin-right: 0.8rem;
}

.modal-footer .btn-secondary {
  background: #eb8258 linear-gradient(180deg, #ee9571, #eb8258) repeat-x;
  border-color: #eb8258;
  color: white;
}

.modal-title {
  color: #495057;
  /* // display: flex; */
  /* // justify-content: space-around; */
  /* // width: 90%; */
  margin-top: 0 !important;
  font-size: 1.6rem !important;
}

.modal-body {
  padding: 1rem !important;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.modal-footer div {
  /* // margin: 0 1.2rem 0 0 !important; */
  margin: 0 !important;
  /* // After removing all "pull-right" I can delete line below: */
  float: none !important;
  text-align: right;
}

@media only screen and (max-width: 767.98px) {
  .modal-footer div {
    margin: 0 !important;
  }
}

button[aria-label="Close"] {
  border: 0;
  background: transparent;
  background-color: transparent;
  padding: 0;
  cursor: pointer;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  float: right;
  font-size: 1.5rem;
  font-weight: 700;
  line-height: 1;
  color: transparent;
  text-shadow: 0 1px 0 #fff;
  /* // opacity: 0.5; */
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

button[aria-label="Close"]:hover {
  color: #495057;
}

.modal-lg,
.modal-xl {
  max-width: 1700px !important;
}
/* // .modal-content{ */
/* //   width:155% !important */
/* // } */
/*#endregion*/

.schoolmodal {
  max-width: 1500px !important;
}

.login-modal .modal-dialog .modal-content {
  width: 100% !important;
}

@media only screen and (max-width: 767.98px) {
  .login-modal {
    margin: 0 4% 0 2.5%;
    width: 92%;
  }
}

.login-modal2 .modal-dialog .modal-content {
  width: 576px !important;
}

@media only screen and (max-width: 767.98px) {
  .login-modal2 {
    margin: 0 4% 0 2.5%;
    width: 92%;
  }
}

.phone-modal .modal-dialog .modal-content {
  /* // width: 120% !important; */
  width: 100% !important;
}

.emergency-modal .modal-dialog .modal-content {
  width: 100% !important;
}

.modal-lg .modal-dialog .modal-content {
  width: 150% !important;
  margin-left: -25%;
}

.Forgot-password-modal .modal-dialog .modal-content {
  width: 100% !important;
}

.sign-up-modal .modal-dialog .modal-content {
  width: 110% !important;
}

.FoodCount-modal .modal-dialog .modal-content {
  width: 150% !important;
}

.fc-event {
  background: transparent !important;
  border: none !important;
  text-align: center;
  text-decoration: dashed;
  font-size: 15px;
}

.fc-unthemed th {
  font-family: "Roboto", sans-serif;
  background: #3b7bd6 !important;
  color: #fff;
  font-size: 14px !important;
  font-weight: 500 !important;
}

.app-header .app-header__content .header-menu .nav-link {
  font-size: 14px;
  color: #343a40;
}

.app-header.header-text-light .app-header-left > .nav > li > .nav-link {
  color: rgba(255, 255, 255, 0.7) !important;
}

.box-title {
  font-size: 1.5em;
  font-weight: 500;
  margin: 26px 0;
  text-align: center;
}

.app-sidebar {
  width: 280px;
}

.app-sidebar .app-header__logo {
  width: 280px !important;
}

.v-sidebar-menu {
  padding: 0 1rem 1rem !important;
}

.modal-lg,
.modal-xl {
  max-width: 1918px !important;
}

.app-main__inner {
  padding: 10px 15px;
  -webkit-box-flex: 1 !important;
  flex: 1 !important;
}

.app-sidebar.sidebar-shadow.app-sidebar-close {
  transform: translateX(0) !important;
  -webkit-transform: translateX(0) !important;
  width: 80px;
}

input,
optgroup,
select,
textarea {
  margin: 0 !important;
}

/*!
  ==================== ==================== ==================== ==================== ====================
  Pe
  ==================== ==================== ==================== ==================== ====================
*/

/* // General Changes */
.date-input {
  width: 20% !important;
  text-align: center !important;
}

.alert-warning {
  color: #806013 !important;
  background: #fdf1d3 !important;
  border-color: #fdebc2 !important;
  padding: 0.5rem 1.25rem;
}

.page-title-actions .btn {
  height: calc(2.25rem + 2px);
  padding: 0.375rem 0.75rem;
  line-height: 1.5;
}

/* // blue line when sidebar menu item is openned */
div.collapse.show {
  height: 100%;
  opacity: 1;
  border-left: solid 5px #e0f3ff;
  margin-left: 20px;
}

.v-sidebar-menu .vsm-header {
  color: #2273c3 !important;
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
  background: #2273c3 !important;
}

.search-wrapper .input-holder .search-icon span::before {
  background: #2273c3 !important;
}

.search-wrapper .input-holder .search-icon span::after {
  border: 2px solid #2273c3 !important;
}

.custom-control-label  {
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
}

input::placeholder,
::-moz-placeholder,
::-webkit-input-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
  color: #818a95 !important;
  font-size: 0.75rem !important;
  font-weight: 400 important;
}

.form-control::placeholder,
.form-control::-moz-placeholder,
.form-control::-webkit-input-placeholder,
.form-control:-ms-input-placeholder,
.form-control:-moz-placeholder {
  color: #818a95 !important;
  font-size: 0.75rem !important;
  font-weight: 400 important;
}

/* // Modal Changes */

div.form-group  {
  margin-bottom: 0.5rem !important;
}
div.modal-body > form > div.box-body > div.form-group  {
  margin-bottom: 0.5rem !important;
}

.text-left.mt-4.mb-4[style*="color: green"]   {
  color: #57a839 !important;
}

/*==================== 
BTN: BRAND ====================*/
/*#region*/
.btn-warning {
  color: #fff !important;
  cursor: pointer;
}
.btn-success {
  background: #61b16c linear-gradient(180deg, #61b16c, #45b454) repeat-x !important;
  border-color: #61b16c !important;

  color: #fff !important;
  cursor: pointer;
}

.btn-success:hover {
  color: #fff;
  background: #45b454 linear-gradient(180deg, #45b454, #3e9d4a) repeat-x !important;
  border-color: #45b454 !important;
}
/*#endregion*/

/*#region*/
/*#endregion*/

/* //~ ICONS */
/*#region*/
.fa-outlined {
  color: transparent;
  /* // font-size: 1.25rem !important; */
  font-size: 1.205rem !important;
  -webkit-text-stroke-width: 1px;
  /* // -webkit-text-stroke-color: #495057;
  // -webkit-text-stroke-color: hsl(215deg, 44%, 61%);
  //   -webkit-text-stroke-color: hsl(215deg, 44%, 50%);
  //   -webkit-text-stroke-color: hsl(215deg, 20%, 50%); */
  -webkit-text-stroke-color: hsl(215deg, 20%, 55%);
}

i[class*="pe-7s-note"],
i[class*="pe-7s-pen"],
i[class*="pe-7s-trash"],
i[class*="pe-7s-check"],
i[class*="pe-7s-close"],
  {
  padding: 0.5rem !important;
  margin: 0 auto;
}

i[class*="pe-7s-note "] {
  /* // color: #b28007; */
  /* // background: 0; */
  padding: 0.5rem !important;
  margin: 0 auto !important;
}

i[class*="pe-7s-note "] {
  color: hsla(42, 92%, 36%, 1) !important;
  background: rgba(253, 241, 211, 0.5) !important;
}
i[class*="pe-7s-note "]:hover {
  background: #f8ddcc;
  color: #212529;
}

i.pe-7s-note:hover {
  filter: brightness(0.85);
}

i.fsize-3.pe-7s-info  {
  padding: 0 0 0 10px !important;
}

i.pe-7s-unlock  {
  padding: 0 !important;
}

i[class*="pe-7s-notebook"] {
  padding: 0 !important;
}

/* // :host ::ng-deep i.fa.fa-edit {
//   padding: 0.25rem !important;
//   padding-left: 0;
// }

// :host ::ng-deep i.fa.fa-trash   {
//   padding: 0.25rem !important;
//   margin: 0 auto;
// } */

.btn.btn-outline-danger.border-0 {
  padding: 0.15rem 0.35rem;
}

a.ng2-smart-action.ng2-smart-action-edit-edit {
  color: #2a6dca;
  padding: 0.15rem 0.35rem;
}

a.ng2-smart-action.ng2-smart-action-delete-delete {
  color: #c71c22;
  padding: 0.15rem 0.35rem;
}

i.fa.fa-edit.text-primary:hover,
a.ng2-smart-action.ng2-smart-action-edit-edit:hover {
  background: #2a6dca;
  color: white;
  padding: 0.15rem 0.35rem;
  border-radius: 0.25rem;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  text-decoration: none;
}

i.fa.fa-trash.text-danger:hover,
a.ng2-smart-action.ng2-smart-action-delete-delete:hover {
  background: #c71c22;
  color: white;
  padding: 0.15rem 0.35rem;
  border-radius: 0.25rem;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
}

i.fa.fa-edit.text-primary:hover,
i.fa.fa-trash.text-danger:hover {
  color: white !important;
}

/* // :host ::ng-deep full-calendar a i,
// :host ::ng-deep ng2-smart-table .ng2-smart-action > i {
//   padding: 0.15rem 0.35rem;
// }

// :host ::ng-deep a i.fa-edit:hover,
// :host ::ng-deep ng2-smart-table .ng2-smart-action > i.fa-edit:hover {
//   background: var(--primary) !important;
//   color: var(--white) !important;
// }

// :host ::ng-deep a i.fa-trash:hover,
// :host ::ng-deep ng2-smart-table .ng2-smart-action > i.fa-trash:hover   {
//   background: var(--danger) !important;
//   color: var(--white) !important;
// } */
/*#endregion*/

/* GENERAL CHANGES */
/*#region*/
select {
  appearance: auto !important;
}

.v-sidebar-menu .collapse.show {
  max-height: 800px !important;
}
.card-hover-shadow-2x:hover {
  box-shadow: 0 0.66875rem 2.3875rem rgba(4, 9, 20, 0.03),
    0 1.1375rem 1.60625rem rgba(4, 9, 20, 0.03),
    0 0.45rem 0.73125rem rgba(4, 9, 20, 0.05),
    0 0.325rem 0.3875rem rgba(4, 9, 20, 0.03);
}

/* // then add class to all <button> tags containing search icons and remove class mb-2 */
.btn-search-icon  {
  padding: 0.375rem 0.75rem !important;
  height: calc(2.25rem + 1px) !important;
  margin-bottom: 0.25rem !important;
  margin-right: 0.5rem !important;
}

/* // Bank Balance page */
.normal-font  {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji" !important;
  font-weight: 400 !important;
}

/*#endregion*/

/* // Menu Options */
td.fc-day-top  {
  padding: 0 0.5rem !important;
}

a.fc-day-grid-event  {
  margin: 0 !important;
}

.mo-selected-day {
  background: #e3e3e3;
}

.fc-unthemed td.mo-selected-day  {
  background: hsla(50, 81%, 94%, 0.54) !important;
  color: red !important;
  font-weight: bold;
}
/*!
  ====================
 SMALL CUSTOMIZATIONS
  ====================
*/
/*#region*/

select.custom-select {
  background-image: none !important;
}

.ngb-dp-month {
  width: 100% !important;
  margin: 0 auto !important;
}
/*#endregion*/
/*!
  ====================
 TOOLTIPS
  ====================
*/
/*#region*/
.info-circle {
  height: 20px;
  width: 20px;
  background-color: transparent;
  border-radius: 50%;
  border: 1px solid #5094da;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}

.info-circle > span {
  /* // vertical-align: sub; */
  vertical-align: baseline;
  color: #5094da;
  position: absolute;
  top: -2px;
  left: 6px;
}

.tooltip-pe {
  cursor: pointer;
  position: relative; /* making the .tooltip-pe span a container for the tooltip-pe text */
  vertical-align: middle;
  padding: 0.375rem 0.25rem;
}

.tooltip-pe:before {
  content: attr(data-text); /* here's the magic */
  position: absolute;
  /* // transform: translateY(-50%);
  // top: -60px; */
  bottom: 35px;
  left: 0;
  margin-top: 15px;
  width: 250px;
  padding: 10px;
  border-radius: 10px;
  background: #000;
  color: #fff;
  text-align: center;
  display: none; /* hide by default */
  font-weight: 400;
  text-align: left;
  text-transform: none;
}
.tooltip-pe:hover:before {
  display: block;
}

.tooltip-pe.left:before {
  /* reset defaults */
  left: initial;
  /* // margin: initial; */

  /* set new values */
  right: 100%;
  margin-right: -50px;
}

/* // This creates the arrow */
.tooltip-pe:after {
  content: "";
  position: absolute;
  left: 50%;
  /* // margin-top: -5px; */
  top: 0%;
  top: -5px;
  /* // transform: translateY(-50%); */
  border: 10px solid #000;
  border-color: black transparent transparent transparent;
  display: none;
}
.tooltip-pe:hover:before,
.tooltip-pe:hover:after {
  display: block;
}

.tooltip-pe:before {
  /* other styles */

  /*  display:none; */

  opacity: 0;
  transition: 0.3s opacity;
}
.tooltip-pe:hover:before {
  opacity: 1;
  z-index: 1;
}
.tooltip-pe:after {
  opacity: 0;
  transition: 0.3s;
}
.tooltip-pe:hover:after {
  opacity: 1;
}

/*#endregion*/

/*!
  ====================
  MISC | OTHER 
  ====================
*/
/*#region*/

.row.align-items-center label {
  margin-bottom: 0;
}

/* // PAGINATION */
ng2-smart-table-pager {
  margin-top: 10px;
}

.ng2-smart-pagination {
  font-size: 10px !important;
  cursor: pointer;
}

.ng2-smart-pagination-nav ul li a.ng2-smart-page-link.page-link {
  font-size: 10px;
  color: var(--primary);
}

.center {
  width: 90%;
  margin-left: 10%;
}

h3.card-title.text-uppercase {
  margin-top: 0;
}

.card-title {
  text-transform: uppercase;
  color: #49596e !important;
  font-weight: 600 !important;
  font-size: 0.88rem !important;
}

.fsize-display {
  font-size: 2rem !important;
}

@media only screen and (max-width: 1220px) {
  .fsize-display {
    font-size: 1.5rem !important;
  }
}

/* #endregion*/
/*!
  ====================
  CALENDAR PAGES 
  ====================
*/
/*#region*/
/* // Need to move these files to local CSS of Preorder Admin */

.fc .fc-button-primary {
  color: var(--gray-dark) !important;
  background-color: #f8f9fa !important;
  border-color: #f8f9fa !important;
}

.fc .fc-toolbar-title {
  font-size: 2.5em;
  color: #495057;
}

.fc-h-event .fc-event-main {
  font-size: 0.85rem !important;
  text-align: left;
  padding-left: 0.8rem;
}

full-calendar .foodright button {
  padding: 0.15rem 0.35rem;
}

.fc-event-main .outerdivs {
  padding-bottom: 0.75rem;
  /* // display: inline-block; */
  display: block;
  white-space: normal;
}

.fc-event-main .fooddiv > label.Header1 {
  padding-top: 0.75rem;
  display: block;
  white-space: normal;
}

tr:nth-child(odd).ng2-smart-row.selected {
  background: transparent !important;
}

.fc-event-main .selecdives {
  inline-size: 90% !important;
  overflow-wrap: break-word !important;
}

.selecdives > span.foodspan {
  margin-left: 0.35rem;
}

.fc-event-main > .lunch_hed:not(:first-child),
.fc-event-main > .foodfiv:not(:first-child) {
  padding-top: 0.75rem;
}
.lunch_hed + br {
  display: none;
}

/* // Applies to all */
a.fc-daygrid-event.fc-daygrid-block-event.fc-h-event.fc-event.fc-event-start.fc-event-end.fc-event-today {
  background-color: transparent !important;
}

/*#endregion*/

/*!
  ====================
  Pe Build 2/7/2020 - Table Fixes
  ====================
*/
/*#region*/

table tr td,
.mat-cell {
  color: #495057;
  font-size: 0.875rem !important;
  margin: 0 !important;
  /* // padding: 0.1rem 0.5rem !important; */
  vertical-align: middle !important;
}

/* // table tr td.fc-event-container  {
//   display: inline-block;
//   vertical-align: top !important;
// } */

.table-action-column,
th.ng2-smart-actions {
  width: 10%;
}

/* FIXING TABLE HEADER COLORS*/

.table-header {
  background-color: #3b7bd6;
  color: white;
  /* font-size: 1.17em; */
}

thead.fc-head,
.fc-unthemed th,
ng2-smart-table thead > tr > th,
.fc-head-container,
th.ng2-smart-th,
th.ng2-smart-actions,
thead tr th {
  background: #5094da !important;
  color: white !important;
  font-size: 0.875rem !important;
  // padding: 0.5rem 0.25rem !important;
}

tr.mat-header-row {
  height: 36px !important;
}

td.mat-cell {
  border-bottom-color: #e9ecef;
}

.mat-sort-header-arrow {
  color: white !important;
}

.mat-table tbody tr.mat-row,
.mat-table tbody tr.mat-footer-row {
  height: 30px !important;
}

:host ::ng-deep table > tbody > tr:nth-child(even) {
  background-color: #f7f7f7 !important;
}

table[mat-table] {
  font-family: inherit;
  thead {
    tr {
      height: 38.4px !important;
    }
  }
  tbody {
    tr {
      height: 36px !important;

      td {
        // color: #495057;
        color: rgba(73, 80, 87, 0.9);
        font-size: 0.875rem !important;
      }

      a {
        color: #3b7bd6;
      }

      td:first-child {
        padding-left: 0.75rem;
      }
    }

    // tr:nth-child(odd):hover {
    //   filter: opacity(0.8);
    // }
    tr:nth-child(even) {
      background-color: hsla(203, 100%, 90%, 0.15);
    }
    tr:nth-child(odd):hover,
    tr:nth-child(even):hover {
      background-color: hsla(203, 100%, 90%, 0.35);
    }
  }
}

.table tbody > tr:nth-child(even),
.table th tr tr:nth-child(even),
.mat-table > tbody > tr:nth-child(even) {
  background: hsla(203, 100%, 94%, 0.35);
  // background: hsla(203, 100%, 94%, 0.35) !important;
}

.table th {
  padding: 0.5rem 0.75rem !important;
}

.table td {
  padding: 0 0.75rem !important;
}
.table td,
.table th {
  border-top: 1px solid hsla(210, 14%, 89%, 0.95) !important;
}

.table thead th {
  border-bottom: none !important;
}

@media only screen and (max-width: 767.98px) {
  .fc-theme-standard td,
  .fc-theme-standard th {
    border: transparent !important;
  }
}
/*#endregion*/

/* FIXING CALENDARS*/
/*#region*/

h2.fc-toolbar-title {
  color: #49596e;
  /* // font-weight: bold; */
  font-size: 1.2rem;
}

:host ::ng-deep .fc-view.fc-month-view.fc-basic-view,
:host ::ng-deep .fc-scroller.fc-day-grid-container {
  overflow-y: hidden !important;
  overflow: hidden !important;
}

a.fc-day-grid-event.fc-end.fc-event.fc-h-event.fc-start {
  background: transparent !important;
  margin: 0 2rem;
}

.fc-event-container {
  cursor: pointer;
}

td.fc-head-container.fc-widget-header,
td.fc-widget-content  {
  padding: 0 !important;
}

.fc-content  {
  font-size: 0.875rem;
}

.foodleft  {
  font-size: 0.875rem;
  width: 80%;
}

.fc-unthemed td.fc-today {
  background: #fcf8e3;
}

a.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end  {
  background: transparent !important;
}

td.fc-day-top  {
  padding: 0 0.5rem !important;
}

a.fc-day-grid-event  {
  margin: 0 !important;
}

.fc-unthemed td.mo-selected-day  {
  background: hsla(50, 81%, 94%, 0.54) !important;
  color: #b92509 !important;
  font-weight: bold;
}
/*#endregion*/

span.fsize-4  {
  font-size: calc(10px + 1vmax) !important;
}

td > input[type="checkbox"]   {
  width: 100%;
}

td > select,
td.p-td-select {
  padding: 0.1rem !important;
}

.btn-sm {
  padding: 0.25rem 0.5rem !important;
  font-size: 0.875rem !important;
}

.form-control-sm {
  border: 1px solid #ced4da !important;
}
.form-control-sm:focus {
  border-color: #8cb2e7;
  outline: 0;
  box-shadow: 0 0 0 0.2rem hsla(221, 100, 50, 0.25);
}

.form-control-sm:disabled,
.form-control-sm[readonly] {
  background-color: #e9ecef;
  border-radius: initial !important;
  border: none !important;
  opacity: 1;
}

/*!  ====================
  FAQ PAGE 
  ==================== */
/*#region*/
ngb-accordion {
  .btn-link {
    margin-bottom: 0;
    color: white;
    font-size: 1.2rem;
    font-weight: normal;
    background-color: transparent;
    text-decoration: none !important;
  }

  .btn-link:hover {
    color: white;
    font-weight: bold;
    text-decoration: none !important;
  }

  .card .bg-info {
    background-color: yellow;
  }
}
/*#endregion*/

/*!  ====================
  TOGGLE BUTTONS
  ==================== */
/*#region*/

.onoffswitch {
  position: relative;
  width: 70px;
  width: 50px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
.onoffswitch-checkbox {
  position: absolute;
  opacity: 0;
  pointer-events: none;
}
.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  /* // border: 1px solid #999999; */
  border-radius: 5px;
  margin: 0;
}
.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  -webkit-transition: 0.3s;
  transition: margin 0.3s; /*/ease-in 0s; */
}
.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  padding: 0;
  height: 25px;
  line-height: 25px;
  font-size: 0.8rem;
  color: white;
  /* // font-family: Trebuchet, Arial, sans-serif; */
  font-weight: bold;
  box-sizing: border-box;
}
.onoffswitch-inner:before {
  content: "ON";
  /* // padding-left: 15px; */
  padding-left: 7px;
  background-color: #3986d5;
  color: #ffffff;
}
.onoffswitch-inner:after {
  content: "OFF";
  /* // padding-right: 12px; */
  padding-right: 5px;
  background-color: #e4e6e8;
  color: #999999;
  text-align: right;
}
.onoffswitch-switch {
  display: block;
  height: 30px;
  width: 20px;
  margin: -2px;
  background: #ffffff;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 55px;
  right: 35px;
  border: 1px solid #d9dbe0;
  border-radius: 4px;
  transition: 0.3s; /*/all 0.3s //ease-in 0s;*/
  -webkit-transition: 0.3s;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}
.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

/* // ARCHITECT UI'S OWN TOGGLE */
.toggle.btn {
  min-width: 59px;
  min-height: 34px;
}

.toggle {
  position: relative !important;
  overflow: hidden;
  border-color: rgba(0, 0, 0, 0.2) !important;
}

.toggle-group {
  position: absolute;
  width: 200%;
  top: 0;
  bottom: 0;
  left: 0;
  transition: left 0.35s;
  -webkit-transition: left 0.35s;
  -moz-user-select: none;
  -webkit-user-select: none;
}

.toggle-on.btn {
  padding-right: 24px;
}

.toggle-on {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 50%;
  margin: 0;
  border: 0;
  border-radius: 0;
}

.toggle-off.btn {
  padding-left: 24px;
}
.toggle-off {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  right: 0;
  margin: 0;
  border: 0;
  border-radius: 0;
  box-shadow: none;
}

.toggle-handle {
  position: relative;
  margin: 0 auto;
  margin-top: -45px;
  padding-top: 0px;
  padding-bottom: 0px;
  height: 100%;
  width: 0px;
  border-width: 0;
  background-color: #fff;
}

.toggle input[type="checkbox"] {
  display: none;
}

.toggle-on.btn-sm,
.btn-group-sm > .toggle-on.btn {
  padding-right: 20px;
}
/*#endregion*/

/*!  ====================
  DUAL BOX FIXES
  ==================== */
/*#region*/
.record-picker li {
  border-top: 0 !important;
}

.record-picker > ul > li > label {
  font-size: 0.865rem !important;
}

/*#endregion*/

/*!  ====================
  Pe - MOBILE FIXES
  ==================== */
/*#region*/

@media only screen and (max-width: 767.98px) {
  table tr th,
  table tr th a {
    font-size: 0.75rem !important;
  }

  .closed-sidebar .app-sidebar {
    min-width: 0 !important;
  }

  .app-sidebar-close {
    width: 0 !important;
  }

  .card-body {
    padding: 1rem 0.25rem 1rem;
  }

  table {
    word-break: normal;
    font-size: calc(0.5vmin + 8px) !important;
  }

  th.ng2-smart-th.arrivalDeparture,
  th.ng2-smart-th.hideDecline  {
    width: 20%;
  }

  h5.final  {
    padding: 1rem 0.5rem 0;
  }

  .row {
    margin: 0;
  }

  .col,
  .col-1,
  .col-10,
  .col-11,
  .col-12,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-auto,
  .col-lg,
  .col-lg-1,
  .col-lg-10,
  .col-lg-11,
  .col-lg-12,
  .col-lg-2,
  .col-lg-3,
  .col-lg-4,
  .col-lg-5,
  .col-lg-6,
  .col-lg-7,
  .col-lg-8,
  .col-lg-9,
  .col-lg-auto,
  .col-md,
  .col-md-1,
  .col-md-10,
  .col-md-11,
  .col-md-12,
  .col-md-2,
  .col-md-3,
  .col-md-4,
  .col-md-5,
  .col-md-6,
  .col-md-7,
  .col-md-8,
  .col-md-9,
  .col-md-auto,
  .col-sm,
  .col-sm-1,
  .col-sm-10,
  .col-sm-11,
  .col-sm-12,
  .col-sm-2,
  .col-sm-3,
  .col-sm-4,
  .col-sm-5,
  .col-sm-6,
  .col-sm-7,
  .col-sm-8,
  .col-sm-9,
  .col-sm-auto,
  .col-xl,
  .col-xl-1,
  .col-xl-10,
  .col-xl-11,
  .col-xl-12,
  .col-xl-2,
  .col-xl-3,
  .col-xl-4,
  .col-xl-5,
  .col-xl-6,
  .col-xl-7,
  .col-xl-8,
  .col-xl-9,
  .col-xl-auto {
    padding: 0;
  }

  .pull-sm-left {
    float: left;
  }
  .pt-sm-0 {
    padding-top: 0 !important;
  }
  .mb-sm-7 {
    margin-bottom: 2.25rem !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .hide-on-mobile {
    display: none !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .ml-sm-5  {
    margin-left: 3rem !important;
  }

  div.fc-header-toolbar > div.fc-left > h2 {
    font-size: calc(10px + 1.5vmax);
  }

  div.card > h5.final {
    font-size: calc(10px + 1vmax);
  }

  .fc-event {
    font-size: calc(0.5vmin + 8px) !important;
  }

  .fc-scroller.fc-day-grid-container  {
    height: auto !important;
  }

  .fc-widget-content,
  .fc-event-container,
  td.fc-event-container   {
    padding: 0 !important;
  }

  td.fc-head-container.fc-widget-header {
    padding: 0 !important;
  }

  .app-page-title .page-title-heading,
  .app-page-title .page-title-wrapper {
    display: inline-block;
  }

  .app-page-title .page-title-actions {
    margin: 0 auto;
  }

  table {
    width: 100%;
    table-layout: auto;
    font-size: calc(5px + 1vh);
  }

  // table tr th {
  //   text-align: center;
  // }

  table tr td,
  table tr th {
    margin: 0;
    padding: 0;
    font-weight: 400;
  }

  table tr td  {
    margin: 0 !important;
    padding: 0.2em 1em !important;
    vertical-align: middle !important;
  }
}
/*#endregion*/

/*!  ====================
  CESAR - SWEET ALERT FIXES
  ==================== */
/*#region*/
.swal2-popup,
.swal2-styled.swal2-confirm {
  font-size: 0.88rem;
}

.swal2-header {
  flex-direction: row;
  justify-content: start;
  padding: 0;
}

.swal2-icon {
  margin: 0;
  transform: scale(0.65);
  width: 5em;
  height: 5em;
}

.swal2-icon .swal2-icon-content {
  display: flex;
  align-items: center;
  font-size: 3em;
}

.swal2-title {
  font-size: 1.5em;
  margin: 0 0 0.1rem;
}

.swal2-content {
  text-align: left;
}

.swal2-actions {
  justify-content: flex-end;
}

button.swal2-styled {
  border-radius: 0.25rem !important;
  font-weight: 400 !important;
  line-height: 1.5;
  padding: 0.375rem 0.75rem !important;
  text-align: center;
  vertical-align: middle;
}

.swal2-styled:focus {
  box-shadow: 0 0 0 1px rgb(50 100 150 / 40%);
}
/*#endregion*/

/*!  ====================
  CESAR - MOBILE FIXES
  ==================== */
/*#region*/
@media only screen and (min-width: 767.98px) {
  .hide-on-desktop-and-tablets {
    display: none;
  }
}
/*#endregion*/

/*!  ====================
  CESAR - RESPONSIVE CSS
  ==================== */
/*#region */
@media only screen and (max-width: 1500px) {
  .partner-slider .owl-next {
    right: 0;
  }
  .partner-slider .owl-prev {
    left: -38px;
  }
  .review-clients .owl-next::before,
  .review-clients .owl-prev::before {
    background-size: 40px;
  }
  .review-clients .owl-prev {
    left: -6%;
  }
  .review-clients .owl-next {
    right: 1%;
  }
  .lfet-text-banar p {
    font-size: 17px;
    line-height: 25px;
    margin: 6px 0;
  }
}

@media only screen and (max-width: 1199px) {
  .container {
    width: 100%;
    max-width: none;
  }

  .hide-on-med-and-down {
    display: none !important;
  }

  .orange-btn {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-size: calc(10px + 1.2vw);
  }
  .banar-btn li a {
    font-size: 1.5vw;
  }
  .banar-btn .blue-btn,
  .banar-btn .orange-btn {
    padding: 1.2vw 2vw !important;
  }
  .btn.blue-btn-small,
  .btn.orange-btn-small {
    padding: 2vh 0 !important;
    width: 190px;
    font-size: 2.2vh;
    margin: 0;
  }
  .banar-btn a {
    width: 100%;
  }
  .lfet-text-banar,
  .banner-text-default {
    left: 40%;
  }
  nav ul li a,
  nav ul li a:visited {
    display: block;
    padding: 0 10px;
    line-height: 70px;
    background: #fff;
    color: #000;
    text-decoration: none !important;
    font-size: 11px !important;
    font-weight: 700;
    text-transform: uppercase;
  }
  .container {
    width: 100%;
  }

  .main-banar .item::after {
    content: "";
    display: block;
    background: rgba(0, 0, 0, 0.52);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .partner-slider .owl-prev {
    left: 0;
  }
  .partner-slider .owl-next {
    right: 70px;
  }
  .partner-slider .owl-next::before,
  .partner-slider .owl-prev::before {
    background-size: 40px;
  }
  .lfet-text-banar h1,
  .lfet-text-banar h2 {
    font-size: 20px;
    line-height: 30px;
  }
  .inner-card h3 {
    margin: 25px 0;
    font-size: 18px;
    min-height: 50px;
  }
  .inner-card p {
    min-height: 200px;
  }
  .school-app {
    margin: 20px 0;
  }
  .check-list {
    min-height: 196px;
  }
  .review-clients .owl-next,
  .review-clients .owl-prev {
    display: none;
  }
  .banar-btn {
    margin-top: 18px;
  }
  .learn-link {
    margin: 10px 0;
  }
  .P-resetpassword .login-box-in-banner {
    max-width: 70%;
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .banner-text-default {
    left: 35%;
  }
  button.request_more_btn {
    float: right;
    margin-top: 8px;
  }
  .price-box h4 {
    font-size: 55px;
  }
  .check-list li {
    font-size: 14px;
  }
  .inner-card h4 {
    font-size: 50px;
  }
  .inner-card h3 {
    min-height: 50px;
  }
  .banar-btn li {
    display: block;
    /* margin: 5px 0 0 !important; */
    margin: 5px auto;
  }
  .btn.blue-btn-small,
  .btn.orange-btn-small {
    font-size: 2.2vh;
    margin: 0;
  }
  .banar-btn .orange-btn {
    display: none;
  }
  .inner-card p {
    min-height: 270px;
    font-size: calc(10px + 0.7vw);
  }
  .inner-card sub,
  .inner-card sup {
    font-size: 14px;
  }

  .testimonial-slide {
    padding: 20px 0;
  }
  .form-heading h2,
  .main-heading h2 {
    font-size: 20px;
  }
  .main-heading-inner h2 {
    font-size: 22px;
  }
  .main-heading-inner {
    margin: 0 0 15px;
  }
  .text-in-Ez p {
    font-size: 13px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 13px;
  }
  .post-details h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .links-box ul li {
    width: 28%;
  }
  .demo-box h2 {
    font-size: 20px !important;
    margin: 10px 0;
  }
  .demo-box p {
    font-size: 16px;
    margin: 10px 0;
  }
}

@media only screen and (max-width: 991px) {
  .panel-body p {
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 24px !important;
    width: 100% !important;
  }
  .main-heading h2 {
    color: #33475b;
    font-size: 28px !important;
    line-height: 30px !important;
  }
  .banar-btn .blue-btn {
    width: 250px;
    margin: 0 10%;
    font-size: 2.5vh;
  }
  .banar-btn a {
    /* padding: 7px 1px !important; */
    /* margin: 0 5%; */
    padding: 0.75rem 0.325rem !important;
    word-wrap: break-word;
    font-size: 12px;
  }

  .main-banar {
    background: rgba(3, 21, 37, 0.82);
    width: 100%;
    height: 35vh;
  }
  .lfet-text-banar {
    left: 50%;
    z-index: 2;
    padding-top: 20vh;
    width: 80%;
    margin: 10%;
  }
  .banner-text-default {
    top: 50%;
    left: 0;
    transform: translate(0%, 25%);
    width: 100%;
  }

  .learn-link,
  .lfet-text-banar p {
    display: none;
  }
  .demo-box,
  .logo-box {
    float: none;
    width: 100%;
  }
  .demo-box {
    vertical-align: top;
  }
  .img-bbox img {
    width: 60%;
  }
  .lfet-text-banar h1 {
    font-size: 25px !important;
    line-height: 32px !important;
    word-break: normal;
    width: 98% !important;
    margin: 0 1%;
  }
  .main-heading-inner h2 {
    font-size: 20px !important;
  }
  .link-more span {
    font-size: 13px;
  }
  .right-btn {
    float: none !important;
  }
  .right-btn .orange-btn {
    background: #f47c4b;
    color: rgb(255, 255, 255);
    padding: 15px 13px;
    font-size: 16px !important;
  }
  #ez-product-title {
    margin-right: 0;
    font-size: 20px !important;
  }
  #banner-img {
    display: none;
  }
  .inner-form {
    width: 80%;
  }
  .P-resetpassword .login-box-in-banner {
    max-width: 80%;
    width: 100%;
  }
}

@media only screen and (max-width: 767px) {
  .hide-on-mobile {
    display: none !important;
  }

  .P-resetpassword .login-box-in-banner {
    max-width: 100% !important;
    width: 100% !important;
    float: none;
    margin: 0 !important;
    padding: 0 1vmin;
  }
  .P-resetpassword .icon {
    font-size: 3.5vw !important;
    min-width: 5vw !important;
  }

  .video-box {
    margin: 0 0 5%;
  }
  iframe {
    width: 100%;
    height: 42vh !important;
  }

  .banar-btn .blue-btn {
    width: 200px;
    margin: 5px 18% 5px 5% !important;
    font-size: 2.3vh;
    padding: 1.6vh 2.2vh !important;
  }
  .banar-btn {
    margin-top: 10px;
  }
  .btn.blue-btn-small,
  .btn.orange-btn-small {
    font-size: 2.2vh !important;
    width: 60%;
  }
  .bg-img {
    display: none;
  }

  .main-banar {
    background: rgba(3, 21, 37, 0.82);
    width: 100%;
    height: 70vh !important;
  }
  .banner-text-default {
    top: 50%;
    left: 0;
    transform: translate(0%, 25%);
    width: 100%;
  }
  .lfet-text-banar {
    left: 45% !important;
    padding-top: 60vh !important;
    width: 90% !important;
  }

  .small-business .container {
    min-width: 100%;
    max-width: 100%;
  }
  .banar-btn,
  .main-heading h4,
  .video-box {
    text-align: center;
  }
  .lfet-text-banar h1 {
    width: 100%;
    text-align: center;
  }
  .navigation .container {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  .navigation {
    padding: 0;
  }

  .food-img img,
  .left-cont-info,
  .main-heading h4,
  .right-cont-info,
  .video-about img,
  .video-box img,
  nav {
    width: 100%;
  }
  .nav-mobile {
    display: block;
  }
  nav {
    padding: 0;
  }
  nav ul {
    display: none;
  }
  nav ul li {
    float: none;
  }
  nav ul li a {
    padding: 15px;
    line-height: 20px;
  }
  nav ul li ul li a {
    font-size: 12px;
    padding-left: 30px;
  }
  .nav-dropdown {
    position: static;
  }
  .nav-list li {
    border-bottom: 1px solid #c4c4c4;
  }
  .nav-list li:last-child {
    border: none;
  }
  .inner-card {
    padding: 20px;
  }
  .check-list,
  .inner-card h3,
  .inner-card p {
    min-height: auto;
  }

  .left-cont-info ul li,
  .right-cont-info ul li {
    font-size: 12px;
  }
  .right-cont-info ul li {
    margin: 0 5px 0 0;
  }
  nav ul li a:not(:only-child)::after,
  nav ul li a:visited:not(:only-child)::after {
    top: 13px;
  }
  .text-box1 p {
    font-size: 13px;
    line-height: 23px;
    margin-bottom: 10px;
    margin-top: 15px;
  }
  .inner-card h3 {
    font-size: 2.5vh;
  }
  .inner-card p {
    font-size: 14px;
    line-height: 22px;
  }
  .feed-back p,
  .inner-card h5,
  .name-client h2,
  .name-client h4,
  .text-box1 p b {
    font-size: 15px;
  }
  .feed-back p {
    line-height: 24px;
  }
  .name-client {
    /* // width: 70vw; */
    margin: 0 -20vw;
  }
  .icon {
    font-size: 11px;
    min-width: 108px;
    line-height: 22px;
  }
  /* // .Ez-box {
  //   padding: 15px 20px;
  // } */
  .gry-main-box {
    padding: 20px 0;
  }
  .faq-box,
  .request-form {
    padding: 15px 0;
  }
  .benefites-box {
    margin: 20px 0 0;
  }
  .main-heading {
    margin-bottom: 15px;
  }
  .main-heading h4 {
    font-size: 16px !important;
    font-weight: 500 !important;
    margin: 15px auto !important;
    line-height: 24px !important;
  }
  .panel-title > a {
    font-size: 14px !important;
  }
  .list-benifits ul li {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .list-benifits {
    margin-top: 15px;
  }
  .pos-banefites {
    padding: 15px;
  }
  .learn-link,
  .lfet-text-banar p {
    display: none;
  }
  .school-review {
    padding: 15px 0;
  }
  .left-cont-info {
    display: none !important;
  }
  .text-box {
    height: 45px;
    margin-bottom: 10px;
  }

  .guid-orange-btn,
  .inner-form {
    width: 90% !important;
    margin: 0 auto;
  }
  .img-box1 {
    display: none;
  }
  .banar-slide .owl-item img {
    min-height: 150px;
  }
  .gry-box-in {
    padding: 20px;
  }
  .guid-orange-btn {
    white-space: inherit;
    font-size: 15px;
  }
  .thum-image img {
    width: 100%;
  }
  .artical-details {
    margin-bottom: 15px;
  }
  .guid-box {
    margin: 10px 0;
  }
  .post-details h3 {
    font-size: 17px;
    line-height: 22px;
  }
  .post-details p {
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .main-heading.inner-space-heading {
    margin: 30px 0 35px;
  }
  .btn.request-orange-btn {
    width: 90%;
  }
  .blog-img-box {
    margin-bottom: 20px;
  }
  .artical-details h3 {
    margin: 15px 0;
  }
  .links-box ul li {
    display: block;
    margin: 10px;
    width: 100%;
  }
  .list-site-map {
    margin-bottom: 15px;
  }
  .heading2 h3 {
    font-size: 15px;
    margin: 0 0 8px;
  }
  .list-site-map li {
    font-size: 13px;
    line-height: 32px;
  }
  .list-site-map li::after {
    font-size: 17px;
    left: -19px;
    top: -4px;
  }
  .demo-box {
    margin: 15px;
  }
  .story-box {
    padding: 10px;
  }
  .story-box h3 {
    font-size: 18px !important;
  }
  .story-box p,
  .story-box ul li {
    line-height: 22px;
    font-size: 13px;
  }
  .demo-box h2 {
    line-height: 24px !important;
  }
  .form-box h3 {
    font-size: 19px !important;
    line-height: 20px !important;
  }
  .form-box {
    padding: 2px 21px;
  }
  .form-filds::after {
    left: -22px;
  }
  .img-bbox img {
    width: 20%;
  }
  .lunch-pnt {
    display: none;
  }
  .P-resetpassword .login-box-in-banner {
    max-width: 100%;
    width: 100%;
  }
}

@media screen and (min-width: 768px) {
  .nav-list {
    display: block !important;
  }
}

@media only screen and (max-width: 600px) {
  /* // .panel-group .panel {
  //   margin-bottom: 5vh;
  // } */
  .orange-btn {
    font-size: calc(10px + 1.5vw);
  }

  .banar-btn .blue-btn {
    font-size: 2.5vh;
    margin: 5px 5% !important;
  }
  .banar-btn {
    margin-top: 20px !important;
  }
}

@media screen and (max-width: 480px) {
  .d_active,
  .tab_drawer_heading {
    border-bottom: 3px solid #000;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
  }
  .tabs {
    display: none;
  }
  .tab_drawer_heading {
    margin: 0;
    padding: 5px 20px;
    display: block;
    cursor: pointer;
    background-color: #c7c8ca;
    color: #000;
  }
}

@media screen and (max-width: 460px) {
  .banar-btn .blue-btn,
  .banar-btn .orange-btn {
    font-size: 2.5vh !important;
    padding: 2vh 3vh !important;
    width: 80% !important;
    margin: 0 10% !important;
  }
  .btn.blue-btn-small,
  .btn.orange-btn-small {
    padding: 2vh 2.5vh !important;
    width: 100% !important;
    margin: 1% auto;
    font-size: 2vh;
  }
  .input-field {
    padding: 10px;
  }
  .feed-back::after,
  .feed-back::before {
    display: none;
  }
  .form-heading h2,
  .main-heading h2 {
    font-size: 15px;
    text-align: center;
    margin-bottom: 20px;
    line-height: 22px;
  }
  .client_image {
    margin-bottom: 20px;
  }
  .main-heading-inner h2 {
    font-size: 15px;
    line-height: 22px;
  }
  .banefites-text-Ez p {
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 10px;
  }
  button.request_more_btn {
    float: none;
    margin-top: 8px;
    display: block;
    width: 100%;
  }
  .inner-text-Ez p {
    text-align: center;
  }
  .btn.orange-submit {
    font-size: 12px;
  }
  .gry-box-in input {
    height: 62px;
  }
  .guid-orange-btn {
    font-size: 13px;
  }
  .icon {
    min-width: 118px;
  }
  .input-container.reCapchaAlign {
    margin-left: 0;
  }
}

@media screen and (max-width: 340px) {
  .support-box input {
    width: 100%;
  }
  .btn.orange-submit {
    width: 100%;
    margin-top: 10px;
  }
}

@media (hover: none) {
  .video-box {
    padding: 0.7vw !important;
  }
  video#ContentPlaceHolder1_vd1 {
    width: 100% !important;
    height: auto !important;
  }
  .row.CheckPosition {
    margin: 0;
  }
  .input-container.reCapchaAlign {
    margin-left: 0;
  }
  .articals {
    margin-top: 5px;
  }
  .thank-you {
    padding: 0 1vh 3vh;
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: portrait) and (-webkit-min-device-pixel-ratio: 1) {
  .learn-link {
    display: none;
  }

  .banar-btn .blue-btn,
  .banar-btn .orange-btn {
    padding: 1vh 1.5vh !important;
    font-size: 1.5vh;
  }
  .btn.blue-btn-small,
  .btn.orange-btn-small {
    font-size: 1.5vh;
    width: 90% !important;
    margin: 1% 5%;
  }
  .banar-btn {
    margin-top: 0 !important;
  }
  .banar-btn li {
    margin-top: 0 !important;
  }
  ul.banar-btn.animatedParent {
    margin-top: 0 !important;
  }
}

/* // Sidebar icons style */
.Sidebar-icon {
  position: absolute;
  left: 5px;
  top: 70%;
  margin-top: -17px;
}

/*#endregion */

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
  // line-height: 1.428571429;
  // color: #333;
}

span.vsm-title {
  font-size: 14px;
}

.mat-table {
  table-layout: fixed;
}

.mat-table a {
  // color: #0366d6;
  color: #1e6bb8 !important;
  text-decoration: none;
}

th.mat-header-cell:first-of-type,
td.mat-cell:first-of-type,
td.mat-footer-cell:first-of-type {
  padding-left: 24px !important;
}

@media only screen and (max-width: 767.98px) {
  th.mat-header-cell,
  .mat-cell {
    font-size: x-small !important;
  }

  th.mat-header-cell,
  td.mat-cell,
  td.mat-footer-cell {
    /* padding-right: 145PX; */
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  th.mat-header-cell:first-of-type,
  td.mat-cell:first-of-type,
  td.mat-footer-cell:first-of-type {
    padding-left: 5px !important;
  }

  .mat-column-Actions {
    text-align: center;
  }
}
