.banar-btn .blue-btn,
.banar-btn .orange-btn {
  background: #fcbf16;
  color: #fff;
  padding: 15px 13px !important;
  width: 225px;
  font-size: 18px !important;
  font-weight: 600;

  border-radius: 4px;
}

.banar-btn .blue-btn {
  background: #fcbf16;
}
.banar-btn .blue-btn:hover {
  background: #e6ae14;
}

.banar-btn .orange-btn {
  background: #f47c4b;
}
.banar-btn .orange-btn:hover {
  background: #e3784e;
}

@media only screen and (max-width: 1500px) {
  .partner-slider .owl-next {
    right: 0;
  }
  .partner-slider .owl-prev {
    left: -38px;
  }
  .review-clients .owl-next::before,
  .review-clients .owl-prev::before {
    background-size: 40px;
  }
  .review-clients .owl-prev {
    left: -6%;
  }
  .review-clients .owl-next {
    right: 1%;
  }
  .lfet-text-banar p {
    font-size: 17px;
    line-height: 25px;
    margin: 6px 0;
  }
  .small-business .container {
    min-width: 100%;
    max-width: 100%;
  }
}

@media only screen and (max-width: 1199px) {
  nav ul li a,
  nav ul li a:visited {
    display: block;
    padding: 0 13px;
    line-height: 70px;
    background: rgb(255, 255, 255);
    color: rgb(0, 0, 0);
    text-decoration: none !important;
    font-size: 11px;
    font-weight: 700;
    text-transform: uppercase;
  }
  .container {
    width: 100%;
  }
  .brand {
    width: 20%;
  }
  .main-banar .item::after {
    content: "";
    display: block;
    background: rgba(0, 0, 0, 0.52);
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    left: 0;
    right: 0;
    z-index: 1;
  }
  .lfet-text-banar {
    left: 50%;
    z-index: 2;
    top: 50%;
  }
  .lfet-text-banar h1 {
    font-size: 30px;

    line-height: 40px;
  }
  .partner-slider .owl-prev {
    left: 0;
  }
  .partner-slider .owl-next {
    right: 70px;
  }
  .partner-slider .owl-next::before,
  .partner-slider .owl-prev::before {
    background-size: 40px;
  }

  .banar-btn .blue-btn,
  .banar-btn .orange-btn {
    padding: 10px 16px;
  }
  .lfet-text-banar h1,
  .lfet-text-banar h2 {
    font-size: 20px;
    line-height: 30px;
  }
  .inner-card h3 {
    margin: 25px 0px;
    font-size: 18px;
    min-height: 50px;
  }
  .btn.orange-btn-small,
  .btn.blue-btn-small {
    padding: 7px 8px;
    font-size: 10px;
  }
  .inner-card p {
    min-height: 200px;
  }
  .school-app {
    margin: 20px 0;
  }
  .check-list {
    min-height: 170px;
  }
  .check-list {
    min-height: 196px;
  }
  .review-clients .owl-prev {
    display: none;
  }
  .review-clients .owl-next {
    display: none;
  }
  .banar-btn {
    margin-top: 18px;
  }
  .learn-link {
    margin: 10px 0;
  }
}

@media only screen and (max-width: 1024px) {
  button.request_more_btn {
    float: right;
    margin-top: 8px;
  }
  .price-box h4 {
    font-size: 55px;
  }
  .check-list li {
    font-size: 14px;
  }
  .inner-card h4 {
    font-size: 50px;
  }
  .inner-card h3 {
    min-height: 50px;
  }
  .banar-btn li {
    display: block;
    margin: 5px 15px 0 0;
  }
  .btn.orange-btn-small,
  .btn.blue-btn-small {
    padding: 7px 8px;
    font-size: 14px;
    width: 100%;
  }
  .inner-card p {
    min-height: 270px;
  }
  .banar-btn .orange-btn {
    display: none;
  }

  .inq-form,
  .testimonial-slide {
    padding: 20px 0;
  }

  .form-heading h2,
  .main-heading h2 {
    font-size: 20px;
  }

  .main-heading-inner h2 {
    font-size: 22px;
  }
  .main-heading-inner {
    margin: 0px 0 15px 0;
  }
  .text-in-Ez p {
    font-size: 13px;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 13px;
  }
  .post-details h3 {
    font-size: 22px;
    line-height: 30px;
  }
  .links-box ul li {
    width: 28%;
  }
  .demo-box h2 {
    font-size: 20px !important;
    margin: 10px 0;
  }
  .demo-box p {
    font-size: 16px;
    margin: 10px 0;
  }
}
@media only screen and (max-width: 991px) {
  .demo-box {
    float: none;
    width: 100%;
    vertical-align: top;
  }
  .logo-box {
    float: none;
    width: 100%;
  }
  .img-bbox img {
    width: 60%;
  }
}

@media only screen and (max-width: 767px) {
  .banar-btn {
    text-align: center;
  }
  .banar-btn .blue-btn {
    width: 170px;
  }
  .lfet-text-banar h1 {
    width: 100%;
    text-align: center;
  }
  .banar-btn li {
    margin-bottom: 5px;
  }
  .lfet-text-banar {
    margin: 0 auto;
    width: 90%;
  }

  .navigation .container {
    width: 100%;
    margin: 0;
    padding: 0;
  }
  nav ul li ul li a {
    font-size: 12px;
  }
  .navigation {
    padding: 0;
  }
  .brand {
    width: 70%;
    padding: 8px 10px;
  }
  .nav-mobile {
    display: block;
  }
  nav {
    width: 100%;
    padding: 0;
  }
  nav ul {
    display: none;
  }
  nav ul li {
    float: none;
  }
  nav ul li a {
    padding: 15px;
    line-height: 20px;
  }
  nav ul li ul li a {
    padding-left: 30px;
  }
  .nav-dropdown {
    position: static;
  }
  .nav-list li {
    border-bottom: 1px solid rgb(196, 196, 196);
  }
  .nav-list li:last-child {
    border: none;
  }

  .inner-card {
    padding: 20px;
  }
  .inner-card h3,
  .inner-card p,
  .check-list {
    min-height: auto;
  }
  .video-box {
    text-align: center;
    margin-bottom: 20px;
  }
  .video-box img {
    width: 100%;
  }

  .banar-btn {
    margin-top: 10px;
  }
  .left-cont-info ul li,
  .right-cont-info ul li {
    font-size: 12px;
  }
  .right-cont-info ul li {
    margin: 0 5px 0 0;
  }
  .right-cont-info {
    text-align: center;
    width: 100%;
  }
  .left-cont-info {
    width: 100%;
    text-align: center;
  }

  nav ul li a:not(:only-child)::after,
  nav ul li a:visited:not(:only-child)::after {
    top: 13px;
  }
  .text-box1 p {
    font-size: 13px;
    line-height: 23px;
    margin: 10px 20px 15px 20px;
  }
  .inner-card h3 {
    font-size: 15px;
  }
  .inner-card p {
    font-size: 14px;
    line-height: 22px;
  }
  .inner-card h5,
  .text-box1 p b,
  .name-client h2,
  .name-client h4 {
    font-size: 15px;
  }
  .feed-back p {
    font-size: 15px;
    line-height: 24px;
  }
  .name-client {
    margin: 10px 0;
  }
  .icon {
    font-size: 11px;
    min-width: 108px;
  }
  .Ez-box {
    padding: 15px 20px;
  }
  .gry-main-box {
    padding: 20px 0;
  }
  .video-about img,
  .food-img img {
    width: 100%;
  }
  .benefites-box {
    margin: 20px 0 0 0;
  }
  .request-form {
    padding: 15px 0;
  }
  .main-heading {
    margin-bottom: 15px;
  }
  .main-heading h4 {
    font-size: 16px;
    font-weight: 500;
    width: 100%;
    text-align: center;
    margin: 15px auto 15px;
    line-height: 24px;
  }
  .faq-box {
    padding: 15px 0;
  }
  .panel-title > a {
    font-size: 14px;
  }

  .panel-title > button {
    font-size: 14px;
  }
  .list-benifits ul li {
    font-size: 15px;
    margin-bottom: 10px;
  }
  .list-benifits {
    margin-top: 15px;
  }
  .pos-banefites {
    padding: 15px;
  }
  .lfet-text-banar p {
    display: none;
  }
  .learn-link {
    display: none;
  }
  .school-review {
    padding: 15px 0;
  }
  .left-cont-info {
    display: none !important;
  }
  .text-box {
    height: 45px;
    margin-bottom: 10px;
  }
  .img-box1 {
    display: none;
  }
  .banar-slide .owl-item img {
    min-height: 150px;
  }
  .inner-form {
    width: 90%;
    margin: 0 auto;
  }
  .gry-box-in {
    padding: 20px;
  }
  .guid-orange-btn {
    width: 90%;
    margin: 0 auto;
    white-space: inherit;
    font-size: 15px;
  }
  .thum-image img {
    width: 100%;
  }
  .post-details p {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 12px;
  }
  .artical-details {
    margin-bottom: 15px;
  }
  .guid-box {
    margin: 10px 0;
  }
  .post-details h3 {
    font-size: 17px;
    line-height: 22px;
  }
  .post-details p {
    font-size: 13px;
    line-height: 22px;
    margin-bottom: 10px;
  }
  .main-heading.inner-space-heading {
    margin: 30px 0 35px;
  }
  .btn.request-orange-btn {
    width: 90%;
  }
  .blog-img-box {
    margin-bottom: 20px;
  }
  .artical-details h3 {
    margin: 15px 0 15px;
  }
  .links-box ul li {
    display: block;
    margin: 10px;
  }
  .list-site-map {
    margin-bottom: 15px;
  }
  .heading2 h3 {
    font-size: 15px;
    margin: 0 0 8px;
  }
  .list-site-map li {
    font-size: 13px;
    line-height: 32px;
  }
  .list-site-map li::after {
    font-size: 17px;
    left: -19px;
    top: -4px;
  }
  .story-box {
    padding: 0 40px;
  }
  .demo-box {
    margin: 15px;
  }
  .story-box {
    padding: 0;
  }
  .story-box h3 {
    font-size: 18px !important;
  }
  .story-box p,
  .story-box ul li {
    line-height: 22px;
    font-size: 13px;
  }
  .demo-box h2 {
    line-height: 24px !important;
  }
  .form-box h3 {
    font-size: 19px !important;
    line-height: 20px !important;
  }
  .form-box {
    padding: 2px 21px;
  }
  .form-filds::after {
    left: -22px;
  }
  .links-box ul li {
    width: 100%;
  }
  .img-bbox img {
    width: 20%;
  }
}
@media screen and (min-width: 768px) {
  .nav-list {
    display: flex !important;
  }
}

@media screen and (max-width: 600px) {
  .lfet-text-banar h1,
  .lfet-text-banar h2 {
    font-size: 16px;
    line-height: 20px;
  }
  .link-more span {
    font-size: 13px;
  }
  .right-btn {
    float: none;
  }
}

@media screen and (max-width: 480px) {
  .tabs {
    display: none;
  }
  .tab_drawer_heading {
    color: rgb(255, 255, 255);
    margin: 0;
    padding: 5px 20px;
    display: block;
    cursor: pointer;
    border-bottom: 3px solid rgb(0, 0, 0);
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
    background-color: rgb(199, 200, 202);
    color: #000;
  }
}
@media screen and (max-width: 460px) {
  .banar-btn .blue-btn,
  .banar-btn .orange-btn {
    padding: 3px 16px;
  }
  .input-field {
    padding: 10px;
  }

  .feed-back::before,
  .feed-back::after {
    display: none;
  }
  .form-heading h2,
  .main-heading h2 {
    font-size: 15px;
    text-align: center;
    margin-bottom: 20px;
  }
  .client_image {
    margin-bottom: 20px;
  }
  .main-heading-inner h2 {
    font-size: 15px;
  }
  .banefites-text-Ez p {
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    margin-bottom: 10px;
  }
  button.request_more_btn {
    float: none;
    margin-top: 8px;
    display: block;
    width: 100%;
  }
  .inner-text-Ez p {
    text-align: center;
  }
  .btn.orange-submit {
    font-size: 12px;
  }
  .gry-box-in input {
    height: 42px;
  }
  .guid-orange-btn {
    font-size: 13px;
  }
}

@media screen and (max-width: 340px) {
  .support-box input {
    width: 100%;
  }
  .btn.orange-submit {
    width: 100%;
    margin-top: 10px;
  }
}

@media screen and (max-width: 480px) {
  .d_active,
  .tab_drawer_heading {
    border-bottom: 3px solid #000;
    letter-spacing: 2px;
    font-weight: 600;
    font-size: 13px;
    text-align: center;
  }

  .tabs {
    display: none;
  }

  .tab_drawer_heading {
    margin: 0;
    padding: 5px 20px;
    display: block;
    cursor: pointer;
    background-color: #c7c8ca;
    color: #000;
  }
}

@media only screen and (max-width: 600px) {
  .panel-group .panel {
    margin-bottom: 0;
  }
  .orange-btn {
    font-size: calc(10px + 1.5vw);
  }

  .banar-btn .blue-btn {
    font-size: 2.5vh;
    margin: 5px 5% 5px 5% !important;
  }

  .banar-btn {
    margin-top: 20px !important;
  }
}

@media only screen and (max-width: 1199px) {
  .hide-on-mobile {
    display: none !important;
  }

  .banar-btn .blue-btn,
  .banar-btn .orange-btn {
    padding: 1.2vw 2vw !important;
  }
}

@media only screen and (max-width: 767px) {
  .right-cont-info ul {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
  }
}

/* css added by rohit.m */

/* @media only screen and (max-width:991px) {
    .main-banar {
        background: rgba(3,21,37,.82);
        width: 100%;
        height: 40vh;
    }

    .banner-text-default {
        top: 50% !important;
        left: 0 !important;
        transform: translate(0%,25%) !important;
    }

    .lfet-text-banar {
        left: 50%;
        z-index: 2;
        padding-top: 20vh;
        width: 80%;
        margin: 10% 5%;
    }
}


@media only screen and (max-width:767px) {
    .lfet-text-banar  {
        left: 50%;
        z-index: 2;
        padding-top: 70vh;
        width: 100%;
    }

    .main-banar  {
        background: rgba(3,21,37,.82);
        width: 100%;
        height: 70vh;
    }

    .banner-text-default  {
        top: 50% !important;
        left: 0 !important;
        transform: translate(0%,25%) !important;
        padding-top: 70vh;
        width: 100%;
    }
} */
