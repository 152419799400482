// Variables

$spacer-lg: 3rem;
$spacer-sm: 1.5rem;

$layout-spacer-lg: 3rem;
$layout-spacer-x: 1.5rem;

$app-sidebar-width: 280px;
$app-sidebar-width-collapsed: 80px;

$logo-height: 23px;
$logo-width: 97px;

$app-header-height: 60px;
$app-footer-height: 40px;
