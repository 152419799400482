/* @import "./bootstrap.min.css"; */
@import "./font-awesome.min.css";
@import "./responsive.css";
@import "./animations.css";

body {
  color: #333;
  line-height: 1.428571429;
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji",
    "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  font-style: normal;
  font-size: 14px;
  transition: 0.3s;
}

ul {
  list-style-type: none;
}

body,
ul {
  margin: 0;
  padding: 0;
}

img {
  max-width: 100%;
}

a:focus {
  outline: 0;
  outline-offset: 0;
}

.btn:focus {
  outline: 0;
  outline-offset: 0;
}

/*=============top nav================*/
/* #region */
.check-list li,
.list-benifits ul li,
ul {
  list-style-type: none;
}

.app-sidebar-close {
  transform: "translateX(0)";
  -webkit-transform: translateX(0);
  width: 0px !important;
}

/* #endregion */
/*==========End=top nav================*/

/*============HEADER=============*/
/* #region */
header {
  background: #fff;
}

.navigation {
  padding: 8px;
  background: #fff;
  position: relative;
  height: 99px;
}

nav {
  float: right;
}

nav ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

nav ul li {
  float: left;
  position: relative;
  z-index: 9;
}

nav ul li a,
nav ul li a:visited {
  display: block;
  padding: 0 14px;
  line-height: 70px;
  background: #fff;
  color: #000;
  text-decoration: none !important;
  font-size: 16px;
  font-weight: 700;
  text-transform: uppercase;
}

.learn-link i {
  color: #fff;
  font-size: 12px;
  margin: 0px 5px;
  vertical-align: middle;
}

nav ul li a:not(:only-child):after,
nav ul li a:visited:not(:only-child):after {
  content: " ▾";
  position: absolute;
  font-size: 20px;
  top: 2px;
  color: #a3a3a3;
}

nav ul li ul li {
  min-width: 260px;
  border-bottom: 1px solid rgb(196, 196, 196);
}

nav ul li ul li a {
  padding: 10px 10px;
  line-height: 20px;
  font-size: 15px;
  font-weight: 500;
  color: #000;
}

.nav-dropdown li a:hover {
  color: rgb(39, 157, 237);
  background: rgb(249, 249, 249) !important;
}

.nav-dropdown {
  position: absolute;
  display: none;
  z-index: 5;
  box-shadow: 0 3px 12px rgba(0, 0, 0, 0.15);
}

ul.normal-bullets {
  list-style: disc !important;
  font-size: 16px !important;
  line-height: 30px !important;
  margin-bottom: 20px !important;
}
/* #endregion */
/* ---- Mobile navigation ---- */
/* #region */
.nav-mobile {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  height: 70px;
  width: 70px;
}

#nav-toggle {
  position: absolute;
  left: 18px;
  top: 22px;
  cursor: pointer;
  padding: 10px 35px 16px 0px;
}

#nav-toggle span,
#nav-toggle span:before,
#nav-toggle span:after {
  cursor: pointer;
  border-radius: 1px;
  height: 5px;
  width: 35px;
  background: rgb(39, 157, 237);
  position: absolute;
  display: block;
  content: "";
  transition: all 300ms ease-in-out;
}

#nav-toggle span:before {
  top: -10px;
}

#nav-toggle span:after {
  bottom: -10px;
}

#nav-toggle.active span {
  background-color: transparent;
}

#nav-toggle.active span:before,
#nav-toggle.active span:after {
  top: 0;
}

#nav-toggle.active span:before {
  transform: rotate(45deg);
}

#nav-toggle.active span:after {
  transform: rotate(-45deg);
}

.active a {
  text-decoration: none;
}
/* #endregion */
/*============End header=============*/

/*==================banar=========================*/
.banar-btn .blue-btn,
.banar-btn .orange-btn,
.inner-card h3,
.links h3,
.main-heading h2,
.submit-btn,
nav ul li a,
nav ul li a:visited,
.blue-btn {
  text-transform: uppercase;
}
.main-banar .item {
  position: relative;
}

.lfet-text-banar {
  position: absolute;
  top: 45%;
  left: 29%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.banar-btn li {
  display: inline-block;
  margin: 0 15px 0 0;
  /* margin: 5px auto; */
}

.lfet-text-banar h1 {
  color: rgb(255, 255, 255);
  margin: 0;
  font-size: 41px;
  line-height: 55px;
  text-shadow: 1px 3px 3px rgba(88, 88, 88, 0.47);
  font-weight: 600;
  width: 515px;
}

.learn-link a {
  font-size: 15px;
  color: rgb(255, 255, 255);
  text-decoration: underline;
  font-weight: 500;
}

.learn-link {
  margin: 10px 0 0;
}

.banar-btn {
  margin-top: 1px;
  text-align: center;
  text-align: left;
}

.banar-btn .orange-btn {
  background: #f47c4b;
  color: #ffff;
  padding: 15px 13px;
  text-transform: uppercase;
  width: 225px;
}

.banar-btn .blue-btn {
  background: #fcbf16;
  /* background: #279ded; */
  color: #fff;
  padding: 15px 31px;
  text-transform: uppercase;
  width: 225px;
}

.banar-btn li a {
  font-size: 18px;
  font-weight: 600;
}

/*==================banar End=====================*/

/*==================PAGE CONTENT====================*/

.text-box1 p {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 25px;
}

.card-vew {
  margin-top: 25px;
}

.text-box1 p b {
  font-weight: bold;
  font-size: 19px;
  color: rgb(0, 0, 0);
}

.img-box1 {
  text-align: center;
}

.school-app {
  margin: 45px 0 20px;
}

.icon-img {
  text-align: center;
  min-height: 110px;
}

.inner-card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  padding: 25px 15px 40px;
  margin-bottom: 30px;
}

.inner-card h3 {
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  margin: 10px 20px;
  min-height: 40px;
  text-transform: uppercase;
}

.inner-card p {
  font-size: 16px;
  font-weight: 400;
  line-height: 26px;
  color: #000;
  min-height: 155px;
}

.inner-card h4 {
  font-size: 55px;
  text-align: center;
  position: relative;
  margin: 30px 0 35px;
}

/*.inner-card h4::after {
    margin: auto;
    display: block;
    width: 70px;
    border-bottom: 1px solid rgb(0, 0, 0);
    content: "";
}*/

.inner-card h5 {
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 21px;
}

.check-list li {
  background: rgba(0, 0, 0, 0) url("../images/check-tinyfied.png") no-repeat
    scroll left top 4px;
  padding-left: 21px;
  text-align: left;
  color: rgb(0, 0, 0);
  font-weight: 400;
  font-size: 15px;
  list-style-type: none;
  margin-bottom: 15px;
  text-decoration: none #bcbcbc;
}

/* ------ INNER CARD BUTTONS ------ */
.btn.blue-btn-small,
.btn.orange-btn-small {
  color: #fff;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
}

.btn.orange-btn-small {
  background: #f47c4b;
  padding: 12px 18px;
  margin: 0 7.5px 0 0;
}

.btn.orange-btn-small:hover {
  background: #e06b3d;
}

.btn.blue-btn-small {
  background: #2081c2;
  padding: 12px 18px;
  margin: 0 0 0 7.5px;
}

.btn.blue-btn-small:hover {
  background: #20689a;
}

.card-vew:after {
  content: "";
  display: block;
  clear: both;
}

.check-list {
  min-height: 150px;
  padding: 0 10px;
}

.inner-card .banar-btn {
  text-align: center;
}

/*======================Our Partner====================*/

.partner-section {
  background: #fafafa;
  padding: 75px 0;
}

.main-heading {
  margin-bottom: 25px;
}

.main-heading h2 {
  text-align: center;
  color: #33475b;
  font-weight: 600;
  font-size: 30px;
  text-transform: uppercase;
  margin: 0;
}

.partner-slider {
  position: relative;
}

.partner-slider .owl-nav.disabled,
.partner-slider .owl-dots.disabled {
  display: block;
}

.partner-slider .owl-prev:before {
  content: "";
  background-image: url(../images/arrowleft.png);
  width: 65px;
  height: 65px;
  position: absolute;
  background-repeat: no-repeat;
  display: inline-block;
}

.partner-slider .owl-item img {
  display: block;
  width: 100%;
  max-width: 100px;
  margin: 0 auto;
}

.partner-slider .owl-next:before {
  content: "";
  background-image: url(../images/arrowright.png);
  width: 65px;
  height: 65px;
  position: absolute;
  background-repeat: no-repeat;
  display: inline-block;
}

.partner-slider .owl-prev {
  position: absolute;
  left: -100px;
  font-size: 0;
  padding: 0;
  top: 0px;
}

.partner-slider .owl-next {
  position: absolute;
  right: -30px;
  font-size: 0;
  padding: 0;
  top: 0px;
}

.partner-slider .owl-carousel .owl-item img {
  width: auto;
  margin: 0 auto;
}

.partner-slider .owl-carousel.owl-one.owl-loaded.owl-drag {
  margin-top: 50px;
}

/*==========================Testimonial========================================*/

.testimonial-slide {
  padding: 75px 0;
}

.client_image {
  width: 139px;
  height: auto;
  margin: 0 auto;
  /* margin-bottom: 45px; */
}

.client_image img {
  /*   border-radius: 50%;*/
  /*   border: 1px solid #279ded;*/
  padding: 4px;
  width: 115px !important;
}

.feed-back {
  max-width: 85%;
  text-align: center;
  margin: 0 auto;
  position: relative;
}

.feed-back:before {
  content: "";
  background-image: url(../images/qut1.png);
  position: absolute;
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  /* top: -18px; */
  right: 100%;
}

.feed-back:after {
  content: "";
  background-image: url(../images/qut1.png);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
  position: absolute;
  width: 35px;
  height: 35px;
  background-repeat: no-repeat;
  bottom: -5px;
  left: 98%;
}

.feed-back p {
  font-size: 19px;
  color: #787878;
  font-weight: 500;
  line-height: 32px;
}

.name-client {
  text-align: center;
  margin: 44px 0;
}

.name-client h2 {
  font-size: 23px;
  color: #000;
  font-weight: 600;
  letter-spacing: 2px;
}

.name-client h4 {
  font-size: 16px;
  color: #787878;
  line-height: 25px;
  letter-spacing: 1px;
}

.testimonial .owl-nav {
  display: none;
}

.inq-form {
  /* background: #fafafa; */
  background: #f1f1f1;
  padding: 75px 0;
}

.input-container {
  display: -ms-flexbox;
  /* IE10 */
  display: flex;
  width: 100%;
  margin-bottom: 15px;
  border: 1px solid #dcdcdc;
}

.icon {
  padding: 17px 10px;
  background: #f5f5f5;
  color: #7e7e7e;
  min-width: 159px;
  text-align: left;
  font-size: 16px;
  font-weight: 400;
}

.input-field {
  width: 100%;
  padding: 10px;
  outline: none;
  border: none;
  overflow: hidden;
}

.form-heading h2 {
  margin-bottom: 27px;
  font-size: 26px;
}

.submit-btn {
  background: RGB(244, 124, 75);
  background-image: none;
  color: rgb(255, 255, 255);
  font-size: 17px !important;
  padding: 12px 14px !important;
  border-radius: 0 !important;
  width: 100%;
  font-weight: 600 !important;
}
.submit-btn:hover {
  background: #e3784e;
  color: #fff;
}

.link-more {
  margin: 28px 0 0;
}

.link-more span {
  font-size: 16px;
  text-decoration: underline;
  color: #565656;
}

.link-more a {
  color: grey;
  text-decoration: underline;
  font-size: 16px;
  font-weight: 400;
}

#back2Top {
  width: 36px;
  line-height: 40px;
  overflow: hidden;
  z-index: 999;
  display: none;
  cursor: pointer;
  -moz-transform: rotate(270deg);
  -webkit-transform: rotate(270deg);
  -o-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
  position: fixed;
  bottom: 30px;
  right: 6px;
  background-color: rgb(242, 97, 39);
  color: rgb(255, 255, 255);
  text-align: center;
  font-size: 16px;
  text-decoration: none;
}

select {
  width: 100px;
  overflow: hidden;
  overflow: -moz-hidden-unscrollable;
  /* background: url("../images/down.png") no-repeat right rgb(241,244,246); */
  background-position-x: right;
  background-position-y: center;
  /* border: 2px double rgb(255, 0, 0); */
  display: inline-block;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: caret;
  appearance: none;
  background-position: 95%;
  /* color: #010405; */
}

.video-box {
  margin: 0 0;
  background: #000;
  padding: 0;
}

/*=====================footer===============================*/

.links h3 {
  font-size: 17px;
  color: rgb(255, 255, 255);
  font-weight: 600;
  margin: 20px 0 17px;
  text-transform: uppercase;
}

.links ul li a {
  font-size: 14px;
  line-height: 24px;
  color: rgb(186, 185, 185);
  font-weight: 400;
  text-transform: capitalize;
  margin: 10px 0;
  display: block;
  text-decoration: none;
}

address p,
address a {
  font-size: 14px;
  line-height: 24px;
  color: rgb(186, 185, 185);
  font-weight: 400;
  text-transform: capitalize;
  margin: 0;
  display: block;
  text-decoration: none;
}

address a:hover {
  color: rgb(186, 185, 185);
  text-decoration: none;
}

address h4 {
  font-size: 15px;
  color: #fff;
  font-weight: 600;
}

/*=======================login-form========================*/

.right-side {
  float: right;
}

.remamber-me::after {
  content: "";
  display: block;
  clear: both;
}

.modal-header.head-popup {
  /* background: rgb(39, 157, 237); */
  background: #407cd0;
  color: rgb(255, 255, 255);
  padding: 15px;
  position: relative;
}

.modal-header.head-popup2 {
  /* background: rgb(39, 157, 237); */
  background: #407cd0;
  color: rgb(255, 255, 255);
  padding: 15px;
  border-top: 6px solid rgb(242, 97, 39);
  position: relative;
}

.modal-header.head-popup2::after {
  width: 0;
  height: 0;
  border-left: 16px solid transparent;
  border-right: 16px solid transparent;
  border-top: 16px solid #407cd0;
  /* border-top: 16px solid rgb(39, 157, 237); */
  position: absolute;
  content: "";
  bottom: -16px;
  text-align: center;
  left: 45%;
  z-index: 999;
}

/* .close {
    position: absolute;
    top: -14px;
    right: -11px;
    font-size: 25px;
    color: rgb(242, 97, 39);
    opacity: 1;
    font-weight: 100;
    background: rgb(255, 255, 255) !important;
    border-radius: 50%;
    width: 30px;
    height: 30px;
    line-height: 33px;
} */

.close {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 25px;
  color: grey;
  opacity: 1;
  font-weight: bold;
  padding: 1vh 1.5vh;
  background: rgb(255, 255, 255) !important;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  line-height: 105px;
  margin-top: -2px;
  margin-right: 10px;
}
/* .close {
    position: absolute !important;
    top: -14px !important;
    right: -11px !important;
    font-size: 25px !important;
    color:black !important;
    opacity: 1 !important;
    font-weight: 100 !important;
    background: rgb(255, 255, 255) !important;
    border-radius: 50% !important;
    width: 30px !important;
    height: 30px !important;
    line-height: 33px !important;
    } */

.close:hover {
  color: black;
  cursor: pointer;
}

#exampleModalLongTitle {
  font-size: 25px;
  /* width: 100%; */
  margin: 0 auto;
  font-weight: 700;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: white;
  background: rgb(255, 255, 255);
  border: 1px solid #ccc;
}

.styled-checkbox:hover + label:before {
  background: #f35429;
}

.styled-checkbox:focus + label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked + label:before {
  background: #f35429;
}

.styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}

.styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}

.styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.remamber-me label {
  font-weight: 300;
}

.login-btn-box a {
  /* background: rgb(39, 157, 237); */
  background: #407cd0;
  color: rgb(255, 255, 255) !important;
  text-transform: uppercase;
  margin-top: 0;
}

.login-btn-box:after {
  content: "";
  display: block;
  clear: both;
}

.remamber-me p {
  margin: 10px 0;
}

/*==================End popup=======================*/

/*=====================About us=========================*/

.school-pos {
  padding: 40px 0;
  background: #ffffff;
}

.text-in-Ez p b {
  font-weight: bold;
  font-size: 19px;
  color: rgb(0, 0, 0);
}

.text-in-Ez p {
  font-size: 15px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 25px;
}

.Ez-box {
  box-shadow: none !important;
  /* padding: 40px 60px; */
  text-align: center;
}

.school_mngmnt_sec .Ez-box.hide-shadow {
  box-shadow: none;
  border: none;
}

.ez-control-box .main-heading h2 {
  text-align: left;
  line-height: 38px;
}

.ez-control-box .main-heading {
  margin-bottom: 25px;
}

.inner-text-Ez p {
  font-size: 15px;
  font-weight: 400;
  line-height: 35px;
  margin-bottom: 25px;
}

.request-btn-full .orange-btn {
  background: #f47c4b;
  color: rgb(255, 255, 255);
  padding: 15px 13px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  font-size: 16px !important;
  font-weight: 600;
}

.request-btn-full .orange-btn:hover {
  background: rgb(221, 91, 39);
}

.gry-main-box {
  background: #fafafa;
  padding: 60px 0;
}

.ez-control-box {
  margin: 25px 0;
}

.video-about {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
}

/* h2#ez-product-title {
  margin-right: 378px;
} */
.main-heading-inner {
  margin: 0 0 8px;
  text-align: left;
}

.main-heading-inner h2 {
  text-align: center;
  color: rgb(51, 71, 91);
  font-weight: 600;
  font-size: 26px;
  text-transform: uppercase;
  margin: 0;
  line-height: 34px;
}

.banefites-text-Ez p {
  font-size: 15px;
  font-weight: 400;
  line-height: 25px;
  margin-bottom: 25px;
}

.icon-calculate {
  padding: 17px 10px;
  background: #279ded;
  color: #fff;
  min-width: 159px;
  text-align: center;
  font-size: 16px;
  font-weight: 600;
}

.ez-control-box h2 {
  text-align: center;
}

.list-benifits ul li {
  background: rgba(0, 0, 0, 0) url("../images/arrow-orange.png") no-repeat
    scroll left top 4px;
  padding-left: 21px;
  text-align: left;
  color: rgb(0, 0, 0);
  font-weight: 400;
  font-size: 15px;
  list-style-type: none;
  margin-bottom: 20px;
}

.download-pdf a {
  font-size: 16px;
  color: #000;
  text-decoration: none;
  vertical-align: middle;
  font-weight: 600;
}

.pos-banefites {
  background: #ffffff;
  padding: 40px;
}

.benefites-box {
  margin: 60px 0 0 0;
}

.lfet-text-banar p {
  color: #fff;
  font-size: 23px;
  width: 665px;
  line-height: 36px;
  margin: 24px 0;
  font-weight: 400;
  margin-bottom: 0;
}

.school-review {
  padding: 60px 0;
  background: #fff;
}

.outer-line {
  border: 1px solid rgb(239, 239, 239);
  position: relative;
  margin-top: 50px;
  padding: 44px 30px;
  position: relative;
}

.outer-line::after {
  content: "";
  width: 101px;
  height: 48px;
  background-color: rgb(255, 255, 255);
  position: absolute;
  transform: rotate(41deg);
  bottom: -44px;
  right: -72px;
  border-left: 1px solid rgb(239, 239, 239);
}

.client-img {
  width: 67px;
  height: 67px;
  border-radius: 50%;
  background: #ddd;
  position: absolute;
  top: 0;
  z-index: 99999;
  left: 8px;
}

.client-img img {
  border-radius: 50%;
}

.outer-line p {
  color: #737679;
  font-size: 15px;
  line-height: 26.29px;
  font-weight: 400;
}

.outer-line h3 {
  color: rgb(80, 86, 90);
  font-size: 18px;
  margin: 18px 0 0;
  font-weight: 500;
  line-height: 30px;
}

.outer-line h5 {
  font-size: 13px;
  color: #636363;
  margin: 0;
  font-weight: 500;
}

.review-clients .item {
  padding: 0 20px 0 0;
}

.review-clients .owl-nav.disabled,
.review-clients .owl-dots.disabled {
  display: block;
}

.review-clients .owl-prev:before {
  content: "";
  background-image: url(../images/arrow2.png);
  width: 65px;
  height: 65px;
  position: absolute;
  background-repeat: no-repeat;
  display: inline-block;
}

.review-clients .owl-item img {
  display: block;
  width: 100%;
  max-width: 100px;
  margin: 0 auto;
}

.review-clients .owl-next:before {
  content: "";
  background-image: url(../images/arrow1.png);
  width: 65px;
  height: 65px;
  position: absolute;
  background-repeat: no-repeat;
  display: inline-block;
}

.review-clients .owl-prev {
  position: absolute;
  left: -100px;
  font-size: 0;
  padding: 0;
  top: 50%;
}

.review-clients .owl-next {
  position: absolute;
  right: -30px;
  font-size: 0;
  padding: 0;
  top: 50%;
}

/*============acordian===========*/

.faq-box {
  padding: 60px 0;
  background: #fafafa;
}

.panel-group .panel {
  border-radius: 0;
  box-shadow: none;
  border-color: #eeeeee;
}

.panel-default > .panel-heading {
  padding: 0;
  border-radius: 0;
  color: #212121;
  background-color: #fafafa;
  border-color: #eeeeee;
}

.panel-title {
  font-size: 16px;
  position: relative;
  font-weight: 500;
}

.panel-title::after {
  font-size: 16px;
  position: relative;
  font-weight: 500;
  background-color: rgb(242, 97, 39);
  content: "Q";
  text-align: center;
  width: 55px;
  min-height: 55px;
  height: 100%;
  position: absolute;
  display: block;
  top: 0px;
  left: 0;
  color: rgb(255, 255, 255);
  line-height: 47px;
}

.panel-title > a {
  display: block;
  padding: 22px 15px 22px 70px;
  text-decoration: none;
  background: #b3bbc4;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
}

.panel-title > button {
  display: block;
  padding: 22px 15px 22px 70px;
  text-decoration: none;
  background: #b3bbc4;
  color: #fff;
  font-size: 17px;
  font-weight: 500;
}

.panel-body {
  position: relative;
  padding: 15px 70px;
}

.panel-body::after {
  font-size: 16px;
  position: relative;
  font-weight: 500;
  background-color: #464646;
  content: "A";
  text-align: center;
  width: 55px;
  height: 100%;
  position: absolute;
  display: block;
  top: 0px;
  left: 0;
  color: rgb(255, 255, 255);
  line-height: 50px;
}

.more-less {
  float: right;
  color: rgb(255, 255, 255);
}

.panel-default > .panel-heading + .panel-collapse > .panel-body {
  border-top-color: #eeeeee;
}

.panel-body p {
  font-size: 17px;
  font-weight: 500;
  color: #101010;
  line-height: 33px;
}

/*=================request form===========================*/

.request-form {
  padding: 60px 0;
}

.main-heading h4 {
  font-size: 18px;
  font-weight: 500;
  width: 75%;
  text-align: center;
  margin: 30px auto 0;
  line-height: 32px;
}

.text-box {
  border-radius: 0;
  height: 59px;
  margin-bottom: 30px;
  border: 1px solid rgb(220, 220, 220);
  color: #838383;
  font-size: 16px;
  font-weight: 500;
}

.form-fields {
  margin-top: 30px;
}

.text-area {
  font-size: 16px;
  font-weight: 500;
  border-radius: 0;
  min-height: 207px;
  border: 1px solid rgb(220, 220, 220);
  color: rgb(131, 131, 131);
  padding: 15px;
}

.price-box h4 {
  font-size: 75px;
  margin: 80px 0 20px;
  position: relative;
  text-align: center;
}

.price-box sup,
.price-box sub {
  font-size: 17px;
  color: rgb(126, 126, 126);
}

.inner-card sup,
.inner-card sub {
  font-size: 17px;
  color: rgb(182, 182, 182);
}

.inner-card sup,
.price-box sup {
  top: -2em;
}

/*----*/

.owl-dots {
  text-align: center;
}

.owl-dots span {
  background-color: #ddd;
  width: 14px;
  height: 14px;
  display: block;
  border-radius: 50%;
  display: inline-block;
}

.owl-dots .owl-dot {
  display: inline-block;
  padding: 0px 4px;
  margin: 0 auto;
  text-align: center;
}

.owl-dot.active span {
  background-color: #000 !important;
}

.about_banner .lfet-text-banar {
  top: 50%;
}

button.request_more_btn {
  color: #fff;
  background-color: #f26127;
  border: none;
  padding: 9px 12px;
  border-radius: 4px;
  font-size: 14px;
  outline: none;
  margin-top: 24px;
  float: right;
}

.right-btn {
  float: right;
  margin-top: 30px;
}

.orange-btn {
  background: #f47c4b;
  color: rgb(255, 255, 255);
  padding: 15px 13px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.orange-btn:hover {
  background: rgb(221, 91, 39);
  color: #fff !important;
}

/*====================Signup====================*/

.gry-box-in {
  background: rgb(250, 250, 250);
  position: relative;
  padding: 43px 0;
  margin-bottom: 50px;
}

.gry-box-in input {
  border-radius: 0;
  border: 1px solid #e3e3e3;
  height: 50px;
  padding: 0 10px;
}

.inner-form {
  width: 41%;
  margin: 0 auto;
}

.divider-line::after {
  content: "";
  height: 1px;
  position: absolute;
  width: 100%;
  left: 0;
  right: 0;
  background: #e3e3e3;
}

.divider-line {
  padding: 30px 0 44px;
}

.inner-form .form-group {
  margin-bottom: 20px;
}

.form-control {
  box-shadow: none;
  -webkit-box-shadow: none;
  font-size: 0.875rem !important;
}

::-webkit-input-placeholder,
::-moz-placeholder,
:-ms-input-placeholder,
:-moz-placeholder {
  color: #747474;
}

.navigation.glossy-shadow {
  box-shadow: 0 5px 10px -5px rgba(51, 51, 51, 0.17);
}

.main-heading.inner-space-heading {
  margin: 40px 50px 35px;
}

/*===========================Contect Sales==================================*/

.main-heading h4 {
  color: rgb(51, 71, 91);
  font-weight: 600;
  font-size: 21px;
  text-transform: uppercase;
  width: 100%;
  margin: 0;
}

.inner-form textarea {
  border-radius: 0;
  border: 1px solid rgb(227, 227, 227);
  min-height: 146px;
}

.support-box input {
  margin-right: 10px;
  width: 64%;
}

.btn.orange-submit {
  width: 30.39%;
  background: rgb(242, 97, 39);
  color: rgb(255, 255, 255);
  padding: 11px 13px;
  text-transform: uppercase;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
  vertical-align: top;
  border-radius: 8px;
}

.support-box {
  text-align: center;
  padding: 0px 0px 30px;
}

/*========================Blog post==========================*/

.blog-post {
  margin: 30px 0 0;
}

.post-details h3 {
  font-size: 25px;
  font-weight: 600;
  line-height: 39px;
  margin: 0 0 15px;
}

.post-details p {
  font-size: 16px;
  font-weight: 400;
  line-height: 31px;
  margin-bottom: 30px;
}

.post-details a {
  color: #279ded;
  text-decoration: none;
}

.hading-here a {
  margin: 30px 0 20px !important;
  font-size: 19px !important;
  font-weight: 600 !important;
  text-decoration: none !important;
  color: rgb(0, 0, 0) !important;
  display: block;
  cursor: pointer;
}

.articals {
  margin-top: 40px;
}

.articals .main-heading {
  margin-bottom: 25px;
}

.artical-details ul li {
  display: inline-block;
  padding: 0 15px 0 0;
  font-size: 14px;
  font-weight: 500;
  color: #000;
  margin-bottom: 10px;
}

.artical-details ul li span {
  color: #b8b8b8;
  margin-right: 10px;
}

.artical-details p {
  font-size: 13px;
  font-weight: 400;
  line-height: 23px;
  margin-bottom: 15px;
}

.artical-details a,
.feedback a {
  color: #781f1b;
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;
}

.guid-orange-btn {
  background: rgb(242, 97, 39);
  color: rgb(255, 255, 255);
  padding: 15px 13px;
  text-transform: uppercase;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  width: 549px;
}

.guid-orange-btn:hover,
.orange-submit:hover {
  background: rgb(221, 91, 39);
  color: #fff !important;
}

.guid-box {
  background: #f0f0f0;
  text-align: center;
  padding: 24px 0;
  margin: 65px 0;
}

.feedback p {
  font-size: 14px;
  color: #888888;
}

.feedback h5 {
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin: 0 0 18px;
}

ul.tabs {
  margin: 0;
  padding: 0;
  float: left;
  list-style: none;
  height: 32px;
  width: 100%;
}

ul.tabs li {
  float: left;
  margin: 0;
  cursor: pointer;
  padding: 0px 21px;
  height: 31px;
  line-height: 31px;
  background-color: rgb(199, 200, 202);
  color: rgb(0, 0, 0);
  overflow: hidden;
  position: relative;
  width: 50%;
  border-bottom: 3px solid #000;
  letter-spacing: 2px;
  font-weight: 600;
  font-size: 13px;
  text-align: center;
}

.tab_last {
  border-right: 1px solid #333;
}

ul.tabs li:hover {
  background-color: #ccc;
  color: #333;
}

ul.tabs li.active {
  background-color: #000;
  color: #fff;
  display: block;
}

.tab_container {
  border-top: none;
  clear: both;
  float: left;
  width: 100%;
  background: #fff;
  overflow: auto;
}

.tab_content {
  padding: 22px 1px;
  display: none;
}

.tab_drawer_heading {
  display: none;
}

.details-guides li {
  display: inline-block;
  width: 49%;
  vertical-align: top;
  font-size: 14px;
  font-weight: 500;
}

.details-guides p {
  color: #000;
  line-height: 20px;
}

.details-guides li span {
  color: #279ded;
}

.tab-lists {
  margin-top: 44px;
}

/*========================Blog-================================*/

.btn.request-orange-btn {
  background: rgb(242, 97, 39);
  color: rgb(255, 255, 255);
  padding: 15px 13px;
  text-transform: uppercase;
  text-align: center;
  font-size: 17px;
  font-weight: 600;
  width: 348px;
}

.blog-img-box {
  margin-bottom: 55px;
}

.btn.request-orange-btn:hover {
  background: rgb(221, 91, 39);
  color: rgb(255, 255, 255) !important;
}

.pagination-box {
  text-align: right;
}

.pagination-count li {
  display: inline-block;
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 50%;
  margin: 4px;
}

.pagination-count li:hover {
  background: rgb(67, 132, 241);
  box-shadow: 0px 1px 14px -3px rgb(153, 153, 153);
}

.pagination-count li a {
  color: #76706d;
  text-align: center;
  display: block;
  text-decoration: none;
}

.pagination-count li a:hover {
  text-decoration: none;
  color: #fff;
}

.thum-image {
  position: relative;
  overflow: hidden;
  height: 260px;
}

.blog-img-box:hover .thum-image img {
  -webkit-transform: scale(1.2);
  transform: scale(1.2);
}

.thum-image img {
  -webkit-transition: 0.6s ease;
  transition: 0.6s ease;
}

/*===================site map==========================*/

.sitemap {
  margin-top: 40px;
}

.sitemap .main-heading h2 {
  text-align: left;
}

.sitemap .main-heading {
  margin-bottom: 25px;
}

.heading2 h3 {
  font-size: 21px;
  font-weight: 600;
  margin: 0 0 15px;
}

.list-site-map li {
  font-size: 15px;
  font-weight: normal;
  line-height: 37px;
  margin-left: 20px;
  position: relative;
}

.list-site-map li a {
  text-decoration: none;
  color: #000;
}

.list-site-map li a:hover {
  /* color: rgb(39, 157, 237); */
  color: #407cd0;
}

.list-site-map li:after {
  content: "\f101";
  display: inline-block;
  font-family: FontAwesome;
  font-size: 26px;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  left: -19px;
  position: absolute;
  top: 0px;
  color: #407cd0;
  /* color: #279ded; */
}

.list-site-map {
  margin-bottom: 55px;
}

/*===========================Who We are==========================*/

.about-new {
  margin-bottom: 48px;
}

.details-weare {
  text-align: center;
}

.details-weare p {
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 25px;
}

.links-box {
  text-align: center;
}

.links-box h3 {
  margin: 0;
  font-size: 21px;
  font-weight: 600;
}

.links-box ul li a {
  font-size: 16px;
  font-weight: 500;
  text-decoration: underline;
}

.links-box ul li {
  display: inline-block;
  margin: 15px 0;
  width: 22%;
}

/*=====================Small Business============================*/

.small-business .container {
  min-width: 990px;
  max-width: 990px;
  padding-left: 0;
  padding-right: 0;
}

.demo-box h2 {
  font-weight: 100;
  font-size: 25px !important;
  line-height: 30px !important;
  margin: 0px 0;
}

.demo-box p {
  font-size: 18px;
  margin: 15px 0;
  font-weight: normal;
  line-height: 1;
  color: rgb(89, 87, 92);
}

.story-box p {
  line-height: 24px;
  font-size: 15px;
}

.story-box ul {
  list-style: disc;
  margin: 0 30px;
}

.story-box ul li {
  font-size: 15px;
  line-height: 24px;
  color: rgb(34, 34, 34);
}

.story-box h3 {
  font-weight: normal;
  font-size: 22px !important;
  line-height: 30px !important;
  margin: 0 0 10px;
}

.story-box {
  padding: 25px 40px;
}

.form-box {
  /* background: rgb(39, 157, 237); */
  background: #407cd0;
  color: #fff;
  padding: 20px 45px;
}

.form-box h3 {
  font-weight: normal;
  font-size: 22px !important;
  line-height: 30px !important;
}

.form-box p {
  font-weight: normal;
  font-size: 15px !important;
  line-height: 18px !important;
}

.form-filds {
  text-align: center;
  position: relative;
}

.form-filds::after {
  content: "";
  border-top: 16px solid transparent;
  border-left: 16px solid rgb(255, 255, 255);
  border-bottom: 16px solid transparent;
  position: absolute;
  left: -45px;
  top: 0;
}

.form-filds input,
.form-filds select {
  height: 39px;
  font-size: 16px;
  padding: 0 10px;
  border: 2px solid rgb(195, 229, 247);
}

.form-filds select {
  position: relative;
  border: solid 1px RGB(187, 187, 187);
  line-height: 1.5;
  text-decoration: none;
  color: rgb(241, 244, 241);
  background-color: rgb(242, 242, 242);
  background-color: -moz-linear-gradient(
    top,
    rgb(248, 248, 248) 1%,
    rgb(225, 225, 225) 100%
  );
  background-color: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(1%, #f8f8f8),
    color-stop(100%, #e1e1e1)
  );
  filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#F8F8F8', endColorstr='#E1E1E1', GradientType=0);
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  display: inline-block;
  cursor: default;
  height: 42px;
}

.requst-one {
  background: rgb(242, 97, 39);
  color: rgb(255, 255, 255);
  padding: 10px;
  text-transform: uppercase;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: 600;
}

.requst-one:hover {
  background: rgb(221, 91, 39);
  color: #fff !important;
}

select.minimal {
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
}

select.minimal:focus {
  background-image: linear-gradient(45deg, green 50%, transparent 50%),
    linear-gradient(135deg, transparent 50%, green 50%),
    linear-gradient(to right, #ccc, #ccc);
  background-position: calc(100% - 15px) 1em, calc(100% - 20px) 1em,
    calc(100% - 2.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1px 1.5em;
  background-repeat: no-repeat;
  border-color: green;
  outline: 0;
}

.Privacy-text {
  text-align: left;
  margin-bottom: 20px;
}

.Privacy-text a {
  color: #fff;
  text-decoration: underline;
}

.small-business {
  margin-bottom: 30px;
}

.logo-box {
  float: left;
  width: 33%;
}

.demo-box {
  float: right;
  width: 65%;
  vertical-align: top;
}

.margin-box {
  margin-top: 30px;
}

.banar-text {
  text-align: center;
}

.banar-text p {
  font-size: 18px;
  margin-top: 40px;
}

.modal-body {
  position: relative;
  padding: 35px 20px;
  background: rgb(236, 236, 236);
}

.img-bbox {
  margin-top: 30px;
  text-align: center;
}

.icon-box {
  width: 50px;
  text-align: center;
  line-height: 50px;
  background: #407cd0;
  /* background: rgb(39, 157, 237); */
  color: rgb(255, 255, 255);
  font-size: 20px;
}

.custom-select {
  font-size: 0.875rem !important;
}

.custom-text {
  text-transform: none !important;
}

.app-page-title {
  padding: 8px 20px !important;
  margin: 0 !important;
  position: relative !important;
}

.modal-body {
  position: relative;
  padding: 35px 20px;
  background: rgb(236, 236, 236);
}

.lunch-pnt {
  position: absolute;
  margin: -36px 0 23px 1px;
}

.school_mngmnt_sec .lunch-pnt {
  margin-top: -45px;
}

.school_mngmnt_sec .lunch-pnt img {
  max-width: 78px;
}

.banar-text p {
  font-size: 18px;
  margin-top: 40px;
}

.right-cont-info ul {
  margin: 0 !important;
}

.lfet-text-banar p {
  color: #fff;
  font-size: 23px;
  width: 665px;
  line-height: 36px;
  margin: 24px 0 0;
  font-weight: 400;
}

.login-btn-box:after {
  content: "";
  display: block;
  clear: both;
}

.modal-body {
  position: relative;
  padding: 20px 20px;
  background: rgb(236, 236, 236);
}

.bg-img img {
  max-width: 100%;
}

.center-box {
  width: 80%;
  margin: 0 auto;
}

.center-btn-search {
  width: 40%;
  margin: 0 auto;
}

.learn-link a {
  color: #fff;
  font-size: 15px;
  color: rgb(255, 255, 255);
  text-decoration: underline;
  font-weight: 500;
}

.ybtn {
  background: #e6ae14;
}
.form-control {
  box-shadow: none;
  -webkit-box-shadow: none;
}

.text-box {
  border-radius: 0;
  height: 59px;
  margin-bottom: 30px;
  border: 1px solid #dcdcdc;
}
.text-area,
.text-box {
  font-size: 16px;
  font-weight: 500;
  color: #838383;
}

/* blog forms */
.articals .main-heading {
  margin-bottom: 25px;
}
.articals .main-heading h1 {
  text-align: center;
  font-weight: 600;
  color: #33475b;
}
.main-heading h1 {
  color: #33475b;
  font-weight: 600;
  font-size: 30px;
  margin: 0;
  text-align: center;
}
.h1 {
  display: block;
}
.h1 {
  display: block;
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  font-weight: bold;
}
h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem;
  font-family: inherit;
  font-weight: 400;
  line-height: 1.2;
  color: inherit;
}

/* AFTER CARE */
/*#region*/
@media only screen and (max-width: 767px) {
  .pos-banefites {
    padding: 10px !important;
  }
}
.h1,
h1 {
  font-size: 36px;
}
h1,
h2,
h3 {
  margin-top: 20px;
}
.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 500;
  line-height: 1.1;
  color: inherit;
}

.post-details h3 {
  font-size: 25px;
  font-weight: 600;
  line-height: 39px;
  margin: 0 0 15px;
}

/*#endregion*/

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .banar-btn .blue-btn {
    font-size: 2vw !important;
  }

  .btn.blue-btn-small,
  .btn.orange-btn-small {
    font-size: 1.5vh;
  }
}

.link-more {
  margin: 28px 0 0;
  text-align: center;
}

.banar-text p {
  font-size: 18px;
  margin-top: 40px;
}

.right-cont-info ul {
  margin: 0 !important;
}

.lfet-text-banar p {
  color: #fff;
  font-size: 23px;
  width: 665px;
  line-height: 36px;
  margin: 24px 0 0;
  font-weight: 400;
}

.login-btn-box:after {
  content: "";
  display: block;
  clear: both;
}

.modal-body {
  position: relative;
  padding: 20px 20px;
  background: rgb(236, 236, 236);
}

.bg-img img {
  max-width: 100%;
}

.center-box {
  width: 80%;
  margin: 0 auto;
}

.center-btn-search {
  width: 40%;
  margin: 0 auto;
}

.learn-link a {
  color: #fff;
  font-size: 15px;
  color: rgb(255, 255, 255);
  text-decoration: underline;
  font-weight: 500;
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
  .banar-btn .blue-btn {
    font-size: 2vw !important;
  }

  .btn.blue-btn-small,
  .btn.orange-btn-small {
    font-size: 1.5vh;
  }
}

/* for substitute request mobile change */
td.fc-daygrid-day.fc-day {
  padding: 0.2em 0.25em !important;
}

table.fc-col-header {
  width: 100% !important;
}

table.fc-scrollgrid-sync-table {
  width: 100% !important;
}

.fc-daygrid-body.fc-daygrid-body-unbalanced.fc-daygrid-body-natural {
  width: 100% !important;
}
/* end substirequest*/
